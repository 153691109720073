/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "./converter.css";
import Card from "react-bootstrap/Card";
import { Formik, Field } from "formik";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import ReactJson from "react-json-view";
import { Helmet } from "react-helmet";

import MonacoEditor from "react-monaco-editor";
import UploadIcon from "../../assets/DND/upload.png";
import Select from "react-select";
import axios from "axios";
import { BiCopyAlt } from "react-icons/bi";
import { RiFileDownloadFill } from "react-icons/ri";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { getSolution } from "../formatters/langchain";
import { BsStars } from "react-icons/bs";
const XMLtoJSONConverter = () => {
  // form validation
  const FormValidationSchema = yup.object({
    inputXMLData: yup.string().notRequired("Json data is required"),
    inputXmlFile: yup.mixed().notRequired("Json file  is required"),
    fileEncoding: yup.string().required("Json file  is required"),
    incantationalLevel: yup
      .string()
      .required("Incantational level  is required"),
    prefixAttributeWith: yup
      .string()
      .notRequired("Root element name  is required"),
    textPropertyName: yup
      .string()
      .notRequired("Root element name  is required"),
    bracketStyle: yup.string().required("Bracket Style is required"),
  });
  let parseJSON;

  const {
    register,
    reset,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      inputXMLData: "",
      inputXmlFile: "",
      fileEncoding: "UTF-8",
      incantationalLevel: "THREE_SPACES",
      prefixAttributeWith: "",
      textPropertyName: "#text",
      bracketStyle: "COLLAPSE",
    },
    resolver: yupResolver(FormValidationSchema),
  });
  const bracketStyleOptions = [
    {
      label: "Collapsed (braces on same line)",
      value: "COLLAPSE",
    },
    {
      label: "Expanded (braces on different line)",
      value: "EXPAND",
    },
  ];
  const [indentationLevel, setIndentationLevel] = useState(3);
   const [showGenerativeAiButton, setShowGenerativeAiButton] = useState(false);
  const [bracketStylesView, setBracketStylesView] = useState(false);
  const copyJson = () => {
    navigator.clipboard
      .writeText(JSON.stringify(formattedJsonValue, null, indentationLevel))
      .then(
        () => {
          if (formattedJsonValue != "") {
            toast.success("JSON copied to clipboard");
          }
        },
        () => {
          if (formattedJsonValue != "") {
            toast.warning("Failed to copy JSON to clipboard");
          }
        }
      );
  };

  const downloadJson = () => {
    const jsonBlob = new Blob(
      [JSON.stringify(JSON.stringify(formattedJsonValue))],
      {
        type: "application/json",
      }
    );
    const url = URL.createObjectURL(jsonBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "FormattedJSON.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
     if (url) {
       toast.success("File downloaded successfully!");
     }
  };

  // const onSubmit = async (data) => {
  //   console.log("submitedDAtaJsonFormatter", data);
  //   setFormattedJsonValue("");

  //   try {
  //     // if (data?.inputXMLData && data?.inputXmlFile) {
  //     //   toast.warning("You need to upload file or use copy Paste only");
  //     // } 
  //     if (data?.inputXmlFile) {
  //       const config = {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       };
  //       let formData = new FormData();

  //       formData.append("inputXmlFile", data.inputXmlFile);
  //       formData.append("fileEncoding", data.fileEncoding);
  //       formData.append("incantationalLevel", data.incantationalLevel);

  //       formData.append("rootElement", data.prefixAttributeWith);
  //       formData.append("textPropertyName", data.textPropertyName);

  //       try {
  //         axios
  //           .post(`${process.env.REACT_APP_BACKEND_URL}/converter/xml-to-json`, formData, config)
  //           .then((res) => {
  //             console.log("response", res);
  //             if (showFormatNewWindow) {
  //               setShowEditor(false);
  //             } else {
  //               setShowEditor(true);
  //             }
  //             // setFormattedJsonValue(res.data.data);
  //             // setFormattedJsonValue(JSON.parse(res?.data?.data));
  //             if (indentationLevel == 10 || indentationLevel == 9) {
  //               console.log("I am from IF");
  //               setFormattedJsonValue(res?.data?.data);
  //             } else {
  //               console.log("i am from else");
  //               setFormattedJsonValue(JSON.parse(res?.data?.data));
  //             }

  //             setSuccess(true);
  //             setSuccessMessage("Conversion Done Successfully!");
  //             setError(false);
  //                reset();
  //             document.getElementById("editor_main_id")?.scrollIntoView({
  //               behavior: "smooth",
  //               block: "start",
  //             });
  //           })
  //           .catch((error) => {
  //             console.error("API request failed:", error);
  //             if (error.response && error.response.data) {
  //                 setSuccess(false)
  //               setError(true);
  //               setShowGenerativeAiButton(true);
  //               setErrorMessage(error.response.data);
  //             } else {
  //               // Display a generic error message
  //               // toast.error("An error occurred while processing your request.");
  //             }
  //           });
  //       } catch (error) {
  //         console.log("error1", error);
  //         toast.warning("Something went wrong!");
  //       }
  //     } else {
  //       try {
  //         let formatData = {
  //           inputXMLData: data.inputXMLData,
  //           incantationalLevel: data.incantationalLevel,
  //           rootElement: data.prefixAttributeWith,
  //           textPropertyName: data.textPropertyName,
  //         };
  //         axios
  //           .post(`${process.env.REACT_APP_BACKEND_URL}/converter/xml-to-json`, data)
  //           .then((res) => {
  //             console.log("response", res);
  //             if (showFormatNewWindow) {
  //               setShowEditor(false);
  //             } else {
  //               setShowEditor(true);
  //             }
  //             // setFormattedJsonValue((res.data.data));
  //             // setFormattedJsonValue(JSON.parse(res?.data?.data));
  //             if (indentationLevel == 10 || indentationLevel == 9) {
  //               console.log("I am from IF");
  //               setFormattedJsonValue(res?.data?.data);
  //             } else {
  //               console.log("i am from else");
  //               setFormattedJsonValue(JSON.parse(res?.data?.data));
  //             }

  //             setSuccess(true);
  //             setSuccessMessage("Conversion Done Successfully!");
  //             setError(false);
  //               //  reset();
  //             document.getElementById("editor_main_id")?.scrollIntoView({
  //               behavior: "smooth",
  //               block: "start",
  //             });
  //             // }
  //           })
  //           .catch((error) => {
  //             console.error("API request failed:", error);
  //             if (error.response && error.response.data) {
  //               // Display the error message from the API response
  //               setError(true);
  //               setShowGenerativeAiButton(true);
  //                 setSuccess(false);

  //               setErrorMessage(error.response.data);
  //             } else {
  //             }
  //           });
  //       } catch (error) {
  //         console.log("error2", error);
  //         toast.warning("Something went wrong!");
  //       }
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };
  const onSubmit = async (data) => {
    console.log("submittedDataJsonFormatter", data);
    setFormattedJsonValue("");
    setErrorMessage(""); // Reset error message before processing

    try {
      if (data?.inputXmlFile) {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        let formData = new FormData();

        formData.append("inputXmlFile", data.inputXmlFile);
        formData.append("fileEncoding", data.fileEncoding);
        formData.append("incantationalLevel", data.incantationalLevel);
        formData.append("rootElement", data.prefixAttributeWith);
        formData.append("textPropertyName", data.textPropertyName);

        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/converter/xml-to-json`,
            formData,
            config
          );
          console.log("response", res);
          if (showFormatNewWindow) {
            setShowEditor(false);
          } else {
            setShowEditor(true);
          }

          if (indentationLevel === 10 || indentationLevel === 9) {
            console.log("I am from IF");
            setFormattedJsonValue(res?.data?.data);
          } else {
            console.log("I am from else");
            setFormattedJsonValue(JSON.parse(res?.data?.data));
          }

          setSuccess(true);
          setSuccessMessage("Conversion Done Successfully!");
          setError(false);
          // reset();
          document.getElementById("editor_main_id")?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        } catch (error) {
          console.error("API request failed:", error);
          if (error.response && error.response.data) {
            setError(true);
            setShowGenerativeAiButton(true);
            setSuccess(false);
            setErrorMessage(
              error.response.data.errorMessage ||
                "An error occurred while processing your request."
            );
          } else {
            console.error("An error occurred while processing your request.");
            setErrorMessage("An error occurred while processing your request.");
          }
        }
      } else {
        try {
          let formatData = {
            inputXMLData: data.inputXMLData,
            incantationalLevel: data.incantationalLevel,
            rootElement: data.prefixAttributeWith,
            textPropertyName: data.textPropertyName,
          };
          const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/converter/xml-to-json`,
            formatData
          );
          console.log("response", res);
          if (showFormatNewWindow) {
            setShowEditor(false);
          } else {
            setShowEditor(true);
          }

          if (indentationLevel === 10 || indentationLevel === 9) {
            console.log("I am from IF");
            setFormattedJsonValue(res?.data?.data);
          } else {
            console.log("I am from else");
            setFormattedJsonValue(JSON.parse(res?.data?.data));
          }

          setSuccess(true);
          setSuccessMessage("Conversion Done Successfully!");
          setError(false);
          // reset();
          document.getElementById("editor_main_id")?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        } catch (error) {
          console.error("API request failed:", error);
          if (error.response && error.response.data) {
            setError(true);
            setShowGenerativeAiButton(true);
            setSuccess(false);
            setErrorMessage(
              error.response.data.errorMessage ||
                "An error occurred while processing your request."
            );
          } else {
            console.error("An error occurred while processing your request.");
            setErrorMessage("An error occurred while processing your request.");
          }
        }
      }
    } catch (error) {
      console.error("Unhandled error:", error);
      setErrorMessage("An unexpected error occurred.");
    }
  };


  useEffect(() => {
    setValue("fileEncoding", "UTF-8");
    setValue("incantationalLevel", "THREE_SPACES");
    setValue("prefixAttributeWith", "");
    setValue("textPropertyName", "#text");
    setValue("bracketStyle", "COLLAPSE");
  }, []);
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  const handleButtonClick = () => {
    const newTab = window.open("", "_blank");

    const htmlContent = `
        <!DOCTYPE html>
        <html>
        <head>
            <title>JSON ConvertedPage</title>
        </head>
        <body>
            <pre>${formattedJsonValue}</pre>
        </body>
        </html>
    `;

    newTab.document.write(htmlContent);
  };

  console.log("errors", errors);
  const CustomInputContent = () => {
    const fileInputRef = useRef(null);

    const [file, setFile] = useState("");
    let fileName;
    const handleBrowseClick = () => {
      fileInputRef.current.click(); // Trigger click event on file input
    };

    const handleFileChange = (e) => {
      // Handle file change event here
      const selectedFile = e.target.files[0];
      console.log("Selected,file", selectedFile.name);
      fileName = selectedFile.name;
      setValue("inputXmlFile", selectedFile);
      setFile(selectedFile?.name);
      setInputJsonValueFile(selectedFile);
    };
    console.log("fileee", file);

    return (
      <div className="main-container">
        <div className="custom-dnd">
          <img src={UploadIcon} className="uploadIcon" alt="upload-icon" />

          <h6 className="custom-input-text">
            Choose a file  
          </h6>
        </div>
        <div>
          <button
            type="button"
            onClick={handleBrowseClick}
            className="browse-file-button"
          >
            Browse File
          </button>
          <input
            accept=".xml"
            type="file"
            multiple={false}
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
        {file && <h6 className="custom-input-text">{file}</h6>}
      </div>
    );
  };
  const fileEncodingOptions = [
    {
      label: "UTF-8",
      value: "UTF-8",
    },
    {
      label: "UTF-16",
      value: "UTF-16",
    },
  ];

  const indentationLevelOptions = [
    {
      label: "2 spaces per indent level",
      value: "TWO_SPACES",
    },
    {
      label: "3 spaces per indent level",
      value: "THREE_SPACES",
    },
    {
      label: "4 spaces per indent level",
      value: "FOUR_SPACES",
    },
    {
      label: "Tab delimited",
      value: "TABS",
    },
    {
      label: "Compact (1 line)",
      value: "COMPACT",
    },
    {
      label: "JavaScript escaped",
      value: "JAVASCRIPT",
    },
  ];

  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        // backgroundColor: isFocused ? "#c00eae" : isSelected ? "#c00eae" : null,
        // backgroundColor: isDisabled
        //   ? undefined
        //   : isSelected
        //   ? "#c00eae"
        //   : isFocused
        //   ? "$c00eae"
        //   : undefined,
        backgroundColor: isDisabled
          ? undefined
          : isFocused
          ? "#BF66B6"
          : isSelected
          ? "#c00eae"
          : undefined,
        // backgroundColor: isDisabled
        //   ? undefined
        //   : isFocused || isSelected
        //   ? "#c00eae"
        //   : undefined,
        // color: isFocused || isSelected ? "#fff" : "#000",
        color: isDisabled
          ? "#ccc"
          : isFocused || isSelected
          ? "white"
          : data.color,
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#c00eae"
              : "#BF66B6"
            : undefined,
        },
      };
    },
  };
   

  const [fileEncodingValue, setFileEncodingValue] = useState(
    fileEncodingOptions[0]
  );
  const [indentationLevelValue, setIndentationLevelValue] = useState(
    indentationLevelOptions[1]
  );
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [bracketStyleValue, setBracketStyleValue] = useState(
    bracketStyleOptions[1]
  );
  const [inputJsonValue, setInputJsonValue] = useState();
  const [inputJsonValueFile, setInputJsonValueFile] = useState();
  const [formattedJsonValue, setFormattedJsonValue] = useState("");
  const [showEditor, setShowEditor] = useState(false);
  const [showFormatNewWindow, setShowFormatNewWindow] = useState(false);

  const [isFileDropped, setIsFileDropped] = useState(false);

  const handleChangeStatus = (fileStatus) => {
    if (fileStatus === "done") {
      setIsFileDropped(true);
    } else {
      setIsFileDropped(false);
    }
  };
  const getUploadParams = ({ file }) => {
    const body = new FormData();
    setValue("inputXmlFile", file);
    setInputJsonValueFile(file);

    body.append("dataFiles", file);
    return { url: "http://localhost:3000/uploadmultifile", body };
  };

  // preview component
  const Preview = ({ meta }) => {
    const { name, size, percent, status, previewUrl } = meta;
    console.log("dropZOneFile.meta", meta);
    return (
      <div className="preview-box">
        <span className="name">{name}</span> -{" "}
        <span className="status">{size}KB</span>
      </div>
    );
  };


    const [loading, setLoading] = useState(false);

    const fetchSolution = async () => {
      let inputJson = "";
      try {
        if (inputJsonValueFile) {
          setLoading(true);
          console.log("inputJsonValueFile", inputJsonValueFile);
          const file = inputJsonValueFile;
          const reader = new FileReader();
          reader.onload = async (e) => {
            inputJson = e.target.result;
            console.log("inputJson", inputJson);
            const solution = await getSolution(
              `Correct this XML and convert into JSON and return JSON ${inputJson}`
            );
            console.log("solution", solution);
            setFormattedJsonValue(JSON.parse(solution));
            setLoading(false);
            setError(false);
          };
          reader.readAsText(file);
        } else {
          setLoading(true);

          // If no file is provided, use a default question or handle the case accordingly
          inputJson = inputJsonValue;
          console.log("inputJson", inputJson);
          const solution = await getSolution(
            `Correct this XML and convert into JSON and return JSON ${inputJsonValue}`
          );
 
          setFormattedJsonValue(JSON.parse(solution));
          setLoading(false);
          setError(false);
        }

        // const ans = await getSolution("Write a code on sum of 2 numbers in js");
        // console.log("langchain.ans", ans);
      } catch (error) {
        console.error("Error fetching solution:", error);
        document.getElementById("editor_main_id")?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    };
  return (
    <div className="container-right mt-5 mt-md-0">
      <Helmet>
        <meta charset="UTF-8" />

        <title>
          Best XML to JSON Converter | Change XML to JSON | SDE Tools
        </title>
        <meta
          name="description"
          content="The best online tool to convert XML data to JSON format at SDE Tools. Transform your XML code to JSON format at the click of a button with this free tool!"
        />
        <meta
          name="keywords"
          content="XML to JSON Conveter, Free XML to JSON Converter"
        />

        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://sdetools.io/converters/xml-json-converter"
        />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="SDE Tools" />
        <meta
          property="og:title"
          content="Best XML to JSON Converter | Change XML to JSON | SDE Tools"
        />
        <meta
          property="og:description"
          content="The best online tool to convert XML data to JSON format at SDE Tools. Transform your XML code to JSON format at the click of a button with this free tool!"
        />
        <meta
          property="og:url"
          content="https://sdetools.io/converters/xml-json-converter"
        />
      </Helmet>

      {/* {error && (
        <>
          <div className="error-message">
            <button className="closeButton">
              <MdOutlineClose onClick={() => setError(false)} />
            </button>
            {errorMessage}
          </div>
          {showGenerativeAiButton && (
            <div className="format-button-generative-ai ">
              <button
                className="format-btn format-button-generative-ai"
                onClick={fetchSolution}
                type="button"
              >
                Convert Using Generative Ai
              </button>
            </div>
          )}
        </>
      )} */}

      {error && (
        <>
          <div className="error-message">
            <div className="row">
              <div className="col-8">{errorMessage}</div>
              <div className="col-4">
                <div className="row">
                  <div className="col-10 gen-ai-button">
                    {showGenerativeAiButton && (
                      <button
                        className="btn-hover color-11"
                        onClick={fetchSolution}
                        type="button"
                      >
                        <BsStars
                          className="mr-2"
                          style={{
                            marginRight: "8px",
                            transform: "rotate(-90deg)",
                            transition: "transform 0.3s ease-in-out", // Optional: adds smooth rotation effect
                          }}
                          size={25}
                        />
                        Smart Fix
                      </button>
                    )}
                  </div>
                  <div className="col-2">
                    {" "}
                    <button className="closeButton">
                      <MdOutlineClose onClick={() => setError(false)} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="error-message d-none">
            <button className="closeButton">
              <MdOutlineClose onClick={() => setError(false)} />
            </button>
            {errorMessage}
          </div>
          {showGenerativeAiButton && (
            <div className="format-button-generative-ai  d-none">
              {/* <button
                className="format-btn format-button-generative-ai"
                onClick={fetchSolution}
                type="button"
              >
               <BsStars /> Format JSON Using Generative Ai
              </button>*/}
              <button
                className="btn-hover color-11"
                onClick={fetchSolution}
                type="button"
              >
                <BsStars size={25} />
                Smart Fix
              </button>
            </div>
          )}
        </>
      )}
      <br />
      {success && (
        <div className="success-message ">
          <button className="closeButton">
            <MdOutlineClose onClick={() => setSuccess(false)} />
          </button>
          {successMessage}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="card-formatter border border-0 card rounded-md bg-white shadow shadow-sm">
          <div className="row">
            <div className="col-12 col-md-5 mobile-responsive-col left-container-padding">
              <div className="copy-paste">
                <label>Copy-paste your XML here</label>
                <textarea
                  onChange={(e) => {
                    setInputJsonValue(e.target.value);
                    setValue("inputXMLData", e.target.value);
                  }}
                  placeholder="Copy-paste your XML here"
                  className="text-area-formatter-new"
                />
              </div>

              <div className="button-section-2 row d-none">
                <div className="col-span-1  col-12 col-md-6    py-2 text-center">
                  <button
                    onClick={() => setShowFormatNewWindow(false)}
                    type="submit"
                    className="format-btn "
                  >
                    Convert XML to JSON
                  </button>
                </div>
                <div className="col-12 d-none col-md-6 mobile-responsive-view-btn-container">
                  <button
                    onClick={() => {
                      if (formattedJsonValue !== "") {
                        handleButtonClick();
                        setShowFormatNewWindow(true);
                      }
                    }}
                    type="submit"
                    className="col-span-1 format-btn  py-2 text-center"
                  >
                    Convert XML to JSON in new window
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-2 column-2-center mobile-responsive-col left-container-padding">
              <div className="copy-paste ">
                <label>Upload XML file</label>
              </div>
              <div>
                <Dropzone
                  classNames="dropzone-container"
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  styles={{
                    dropzone: {
                      overflow: "auto",
                      border: "1px dashed  #C6C3C3",
                      background: "#f5f5f5",
                      width: "100%",
                      height: "145px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                    inputLabelWithFiles: { margin: "20px 3%" },
                  }}
                  canRemove={true}
                  maxFiles={1}
                  PreviewComponent={Preview}
                  accept=".xml"
                  inputContent={<CustomInputContent />}
                />
              </div>

              <div className="col-span-1  col-12 col-md-12  py-2 text-center">
                <button className="format-btn" type="submit">
                  Convert
                </button>
              </div>
            </div>

            <div className="col-12 col-md-5 mobile-responsive-col ">
              <div className="row mobile-res-row" id="editor_main_id">
                <div className="col-8">
                  <h6 className="formatted-heading">Converted JSON</h6>
                </div>

                <div className="editor_holder_button col-4">
                  <button
                    onClick={copyJson}
                    type="button"
                    className="editor_holder_button_bg"
                  >
                    <BiCopyAlt />
                  </button>
                  <button
                    onClick={() => {
                      if (formattedJsonValue != "") {
                        downloadJson();
                      }
                    }}
                    type="button"
                    className="editor_holder_button_bg ms-3"
                  >
                    <RiFileDownloadFill />
                  </button>
                </div>
              </div>
              <div
                className={`editor-border-new ${
                  formattedJsonValue ? "editor-scroll-enable" : ""
                }`}
              >
                {formattedJsonValue && (
                  <div className="max-h-60   react-json-editor ">
                    {indentationLevel != 10 && indentationLevel != 9 ? (
                      <ReactJson
                        displayArrayKey={false}
                        enableClipboard={false}
                        displayObjectSize={false}
                        displayDataTypes={false}
                        indentWidth={indentationLevel || 3}
                        src={formattedJsonValue}
                        collapsed={bracketStylesView}
                        name={false}
                      />
                    ) : (
                      <Editor
                        value={formattedJsonValue}
                        onValueChange={(code) => setFormattedJsonValue(code)}
                        highlight={(code) => highlight(code, languages.js)}
                        padding={10}
                        readOnly
                        style={{
                          fontFamily: '"Fira code", "Fira Mono", monospace',
                          fontSize: 12,
                          // border: "2px solid ",
                          borderColor: "#e4e4e4",
                          borderRadius: "10px",
                          minHeight: "80%",
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
              {/*<Editor
                value={formattedJsonValue}
                onValueChange={(code) => setFormattedJsonValue(code)}
                highlight={(code) => highlight(code, languages.js)}
                padding={10}
                disabled
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                  border: "2px solid ",
                  borderColor: "#e4e4e4",
                  borderRadius: "10px",
                  minHeight: "80%",
                }}
              />*/}
            </div>
            <div className="button-section row">
              <div className="col-span-1 col-12 col-md-4 copy-paste  bg-gray-200 py-2 text-start">
                <label className="label-drop-down">File Encoding</label>
                <Select
                  options={fileEncodingOptions}
                  isClearable={false}
                  styles={styles}
                  defaultValue={fileEncodingOptions[0]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setFileEncodingValue(e);
                    // setCourseName(e);
                    // const selectedValues = e.map((option: any) => option.value);
                    // console.log("selectedValues", selectedValues);
                    setValue("fileEncoding", e.value);
                  }}
                  classNamePrefix="select"
                  value={fileEncodingValue}
                />
              </div>

              <div className="col-span-1 col-12 copy-paste  col-md-4 bg-gray-200 py-2 text-start">
                <label className="label-drop-down">Indentation level</label>
                <Select
                  options={indentationLevelOptions}
                  isClearable={false}
                  styles={styles}
                  defaultValue={indentationLevelOptions[1]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setIndentationLevelValue(e);
                    setFormattedJsonValue("");
                    setSuccess(false);
                    setValue("incantationalLevel", e.value);
                    switch (e.value) {
                      case "TWO_SPACES":
                        setIndentationLevel(2);
                        break;
                      case "THREE_SPACES":
                        setIndentationLevel(3);
                        break;
                      case "FOUR_SPACES":
                        setIndentationLevel(4);
                        break;
                      case "TABS":
                        setIndentationLevel(8);
                        break;
                      case "COMPACT":
                        setIndentationLevel(9);
                        break;
                      case "JAVASCRIPT":
                        setIndentationLevel(10);
                        break;
                      default:
                        setIndentationLevel(3);
                        break;
                    }
                  }}
                  classNamePrefix="select"
                  value={indentationLevelValue}
                />
              </div>
              <div className="col-span-1 col-12 col-md-4 copy-paste bg-gray-200 py-2 text-start">
                <label className="label-drop-down">
                  Prefix attributes with
                </label>
                <input
                  onChange={(e) => {
                    setValue("prefixAttributeWith", e.target.value);
                  }}
                  className="input-area-formatter"
                  placeholder="@"
                />
              </div>
              <div className="col-span-1 col-12 col-md-4 copy-paste bg-gray-200 py-2 text-start">
                <label className="label-drop-down">#text property name</label>

                <input
                  onChange={(e) => {
                    setValue("textPropertyName", e.target.value);
                  }}
                  className="input-area-formatter"
                  defaultValue={"#text"}
                />
              </div>
              <div className="col-span-1 col-12 col-md-8 copy-paste  bg-gray-200 py-2 text-start">
                <label className="label-drop-down">Bracket style</label>
                <Select
                  options={bracketStyleOptions}
                  isClearable={false}
                  styles={styles}
                  // defaultInputValue={bracketStyleOptions[0]}
                  defaultValue={bracketStyleOptions[1]}
                  onChange={(e) => {
                    setBracketStyleValue(e);
                    setValue("bracketStyle", e.value);
                    switch (e.value) {
                      case "COLLAPSE":
                        setBracketStylesView(true);
                        break;
                      case "EXPAND":
                        setBracketStylesView(false);
                        break;

                      default:
                        setBracketStylesView(false);
                        break;
                    }
                  }}
                  classNamePrefix="select"
                  value={bracketStyleValue}
                />
              </div>
            </div>
          </div>
        </Card>
      </form>

      <div className="main-content-container">
        <div className="sub-heading">
          <div className="heading-formatter">
            <h1 className="heading-formatter-text">
              Best XML to JSON Converter Online{" "}
            </h1>
            <p className="heading-paragraph">
              This XML to JSON Converter seamlessly transforms XML data
              structures into their equivalent JSON format, enabling efficient
              data exchange and integration across diverse applications. Both
              JSON values and JSON keys are turned into XML elements and element
              values, respectively. As XML employs several element types without
              corresponding JSON representations, this approach is not 100%
              accurate. This tool assists in two bracket styles with braces on
              the same line as well different lines. It uses UTF-8 and UTF-16
              for encoding the text and offers a total of six levels of
              indentation: 2 spaces, 3 spaces, 4 spaces, tab delimited, compact
              (1 line) and JavaScript escaped.
            </p>
          </div>
          <h2 className="main-heading">
            Benefits of Our XML to JSON Converter:
          </h2>
          <ul className="sub-list-ul">
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
                className="bold"
              >
                Effortless Conversion:
              </h3>
              Simply paste your XML code into the provided input field, and our
              tool will instantly generate the equivalent JSON representation.
              No complex setups or technical expertise required – just copy and
              convert!
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Precise Data Mapping:
              </h3>
              Our converter accurately maps XML elements and attributes to their
              corresponding JSON structures.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Improved Flexibility:
              </h3>
              JSON's lightweight nature makes it a popular choice for data
              exchange. Our converter empowers you to leverage the advantages of
              JSON, unlocking new possibilities for data integration.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Online Accessibility and Free Use:
              </h3>
              Our XML to JSON Converter is readily available online, accessible
              from any device with an internet connection. There's no need for
              downloads or installations; simply copy and paste your XML data,
              and receive your converted JSON in seconds!
            </li>
          </ul>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">What is XML to JSON Converter?</h6>
          <p className="main-heading-paragraph">
            Although they are distinct, JSON and XML are both widely used data
            formats. This converter fills the space by converting XML data into
            an equivalent JSON document. To produce a JSON format with key-value
            sets and stacked items, it scans the XML components, features, and
            their relationship to one another.
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">Why use XML to JSON Converter?</h6>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>It transforms XML data into well-structured JSON format.</li>
              <li>
                As compared to XML, JSON is frequently considered to be an
                easier to use and lighter format. In some cases, XML to JSON
                conversion can speed up data processing.
              </li>
              <li>
                This converter lets you transform XML data into the necessary
                JSON format for API consumption. Several APIs (Application
                Programming Interfaces) rely on JSON for data interchange.
              </li>
            </ul>
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">
            Why use SDE Tools Validator and Formatter?
          </h6>
          <p className="main-heading-paragraph">
            It automatically formats your code in accordance with the coding
            standards. SDE Tools offers you various advantages:
          </p>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                It helps in increasing the productivity as it provides you
                features like code completion and highlighting the syntax
                minimizing errors.
              </li>
              <li>
                To fit your coding style, the editor lets you adjust formatting
                choices and handles several file encodings.
              </li>

              <li>
                It enhances the readability by providing clarity to all the
                developers.
              </li>
            </ul>
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">How does a SDE Tools Editor work?</h6>
          <p className="main-heading-paragraph">
            The validator functions similarly to a computerized code reviewer.
            It goes line by line through your code. It checks:
          </p>
          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                Mistakes such as mismatched braces, improper function calls, and
                misplaced semicolons.
              </li>
              <li>
                The coding style differentiation such as indentation spaces.
              </li>

              <li>
                A report is provided by the validator which includes identified
                problems and enables you to fix those problems.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default XMLtoJSONConverter;
