import React, { useRef } from 'react'
import "./blog.css"
import IM1 from "../../../assets/blog/remote-interview-550x350.avif"
import IM2 from "../../../assets/blog/software-engineering-roles-550x350.avif";
import IM3 from "../../../assets/blog/personal-branding-550x350.avif"
import { Link, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "swiper/css/navigation";
 
import { Navigation } from "swiper/modules";
import { Autoplay } from "swiper/modules";

const BlogSlider = () => {

  const navigate =useNavigate()

const swiperRef = useRef(null);

const goToNextSlide = () => {
  if (swiperRef.current) {
    swiperRef.current.swiper.slideNext();
  }
};

const goToPrevSlide = () => {
  if (swiperRef.current) {
    swiperRef.current.swiper.slidePrev();
  }
};


// function generateSlug(title) {
//   return title
//     .toLowerCase() // Convert to lowercase
//     .replace(/\s+/g, "-") // Replace spaces with hyphens
//     .replace(/[^\w\-]+/g, "") // Remove special characters
//     .replace(/\-\-+/g, "-") // Replace multiple hyphens with a single hyphen
//     .trim(); // Remove leading or trailing hyphens
// }







    const blogArticles = [
      {
        id: 1,
        image: IM1,
        category: "Guide for Tools",
        title: "A Step-by-Step Guide to Formatting JSON Code for Beginners",
        description:
          "JSON, or JavaScript Object Notation, has emerged as the de-facto standard for data interchange across diverse applications and systems. Its human-readable format, combined with its efficiency and versatility, has strengthened its position as a preferred choice for developers worldwide. ",
        date: "Aug 02, 2024",
        readTime: "9 MIN READ",
        slug: "guide-to-formatting-json-code-for-beginners",
      },
      // {
      //   id: 2,
      //   image: IM2,
      //   category: "SOFTWARE ENGINEERING",
      //   title: "Understanding Different Software Engineering Roles",
      //   description:
      //     "From front-end to back-end development, each role in software engineering has its own importance...",
      //   date: "August 15, 2024",
      //   readTime: "7 MIN READ",
      //   slug: "software-engineering-roles",
      // },
      // {
      //   id: 3,
      //   image: IM3,
      //   category: "PERSONAL BRANDING",
      //   title: "Building Your Personal Brand as a Developer",
      //   description:
      //     "Personal branding is crucial for developers. It helps you stand out and showcase your skills...",
      //   date: "September 10, 2024",
      //   readTime: "5 MIN READ",
      //   slug: "personal-branding",
      // },
      // {
      //   id: 4,
      //   image: IM1,
      //   category: "CAREER DEVELOPMENT",
      //   title: "Preparing for Onsite vs. Remote Interviews",
      //   description:
      //     "Ever thought about how interview prep changes for onsite vs. remote ones? It’s an important question…",
      //   date: "July 30, 2024",
      //   readTime: "9 MIN READ",
      //   slug: "remote-interview",
      // },
      // {
      //   id: 5,
      //   image: IM2,
      //   category: "SOFTWARE ENGINEERING",
      //   title: "Understanding Different Software Engineering Roles",
      //   description:
      //     "From front-end to back-end development, each role in software engineering has its own importance...",
      //   date: "August 15, 2024",
      //   readTime: "7 MIN READ",
      //   slug: "software-engineering-roles",
      // },
      // {
      //   id: 6,
      //   image: IM3,
      //   category: "PERSONAL BRANDING",
      //   title: "Building Your Personal Brand as a Developer",
      //   description:
      //     "Personal branding is crucial for developers. It helps you stand out and showcase your skills...",
      //   date: "September 10, 2024",
      //   readTime: "5 MIN READ",
      //   slug: "personal-branding",
      // },
    ];
 
  return (
    <div className="articles row">
      <div className={`carousel-container `}>
        <h4
          style={{ textTransform: "uppercase" }}
          className="heading-formatter-text "
        >
          Latest Blogs
        </h4>
        <div className={`mobileSHow mt-4 mb-4 `}>
          <button onClick={goToPrevSlide} className="rotateImage">
            <FaArrowLeft className="arrow-btn" />
          </button>

          <button onClick={goToNextSlide} className="ms-3">
            <FaArrowRight className="arrow-btn" />
          </button>
        </div>
      </div>

      <Swiper
        navigation={false}
        className={`swiper-wrapper center-article`}
        speed={3000}
        slidesPerView={3}
        loop={true}
        // autoplay={{ delay: 3000, disableOnInteraction: false }}
        // modules={[Autoplay, Navigation]}
        ref={swiperRef}
        breakpoints={{
          320: { slidesPerView: 1, spaceBetween: 20 },
          425: { slidesPerView: 2, spaceBetween: 20 },
          720: { slidesPerView: 3, spaceBetween: 10 },
          1024: { slidesPerView: 3, spaceBetween: 10 },
          1229: { slidesPerView: 3, spaceBetween: 10 },
          1396: { slidesPerView: 3, spaceBetween: 10 },
          1536: { slidesPerView: 3, spaceBetween: 10 },
        }}
      >
        {blogArticles.map((article, index) => (
          <SwiperSlide key={index}>
            <Link to={`/blog/${article?.slug}`} className="read-more">
              <article className="article-width ">
                <div className="article-wrapper">
                  <figure>
                    <img src={article.image} alt={article.title} />
                  </figure>
                  <div className="article-body">
                    <h6 className="category-title">{article.category}</h6>
                    <h2 className="blog-title">{article.title}</h2>
                    <p className="blog-description">{article.description}</p>
                    <p className="blog-date">{article.date}</p>
                    <br />
                    <hr />
                    <Link to={`/blog/${article?.slug}`} className="read-more">
                      {article.readTime}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </article>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default BlogSlider