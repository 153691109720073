/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "./converter.css";
import Card from "react-bootstrap/Card";
import { Formik, Field } from "formik";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ReactJson from "react-json-view";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import MonacoEditor from "react-monaco-editor";
import UploadIcon from "../../assets/DND/upload.png";
import Select from "react-select";
import axios from "axios";
import { BiCopyAlt } from "react-icons/bi";
import { RiFileDownloadFill } from "react-icons/ri";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { getSolution } from "../formatters/langchain";
import { Helmet } from "react-helmet";
import { BsStars } from "react-icons/bs";
const CSVToJSON = () => {
  const [redirectToHtmlPage, setRedirectToHtmlPage] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // form validation
  const FormValidationSchema = yup.object({
    inputCSVData: yup.string().notRequired("Json data is required"),
    inputCsvFile: yup.mixed().notRequired("Json file  is required"),
    fileEncoding: yup.string().required("Json file  is required"),
    incantationalLevel: yup.string().required("Indentation Level  is required"),
    bracketStyle: yup.string().required("Bracket Style Level  is required"),
    csvHeaderStatus: yup.string().required("CSV Header is required"),
    emptyValuesOption: yup.string().required("Empty Values is required"),
    csvDelimiter: yup.string().required("CSV Delimiter is required"),
    csvQuoteCharacter: yup.string().required("CSV Quote is required"),
  });
  let parseJSON;

  const {
    register,
    reset,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      inputCSVData: "",
      inputCsvFile: "",
      fileEncoding: "UTF-8",
      csvHeaderStatus: "Yes",
      csvDelimiter: ",",
      csvQuoteCharacter: `DOUBLE_QUOTE`,
      emptyValuesOption: `emptyValue`,
      incantationalLevel: `THREE_SPACES`,
      bracketStyle: `COLLAPSE`,
    },
    resolver: yupResolver(FormValidationSchema),
  });
useEffect(() => {
  setValue("fileEncoding", "UTF-8");
  setValue("csvHeaderStatus", "Yes");
  setValue("csvQuoteCharacter", `DOUBLE_QUOTE`);
  setValue("incantationalLevel", "THREE_SPACES");
  setValue("bracketStyle", "COLLAPSE");
  setValue("emptyValuesOption", "emptyValue");
  setValue("csvDelimiter", ",");
}, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
   const copyJson = () => {
     navigator.clipboard
       .writeText(JSON.stringify(formattedJsonValue, null, indentationLevel))
       .then(
         () => {
           if (formattedJsonValue != "") {
             toast.success("JSON copied to clipboard");
           }
         },
         () => {
           if (formattedJsonValue != "") {
             toast.warning("Failed to copy JSON to clipboard");
           }
         }
       );
   };

  const downloadJson = () => {
    const jsonBlob = new Blob([JSON.stringify(formattedJsonValue)], {
      type: "application/xml",
    });
    const url = URL.createObjectURL(jsonBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "convertedXml.xml";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
     if (url) {
       toast.success("File downloaded successfully!");
     }
  };

  const [indentationLevel, setIndentationLevel] = useState(3);
  const [bracketStylesView, setBracketStylesView] = useState(false);
  // const onSubmit = async (data) => {
  //   console.log("submitedDAtaJsonFormatter", data);
  //   setFormattedJsonValue("");

  //   try {
  //     if (data?.inputCSVData && data?.inputCsvFile) {
  //       toast.warning("You need to upload file or use copy Paste only");
  //     } else if (data?.inputCsvFile) {
  //       const config = {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       };
  //       let formData = new FormData();

  //       formData.append("inputCsvFile", data.inputCsvFile);
  //       formData.append("fileEncoding", data.fileEncoding);
  //       formData.append("incantationalLevel", data.incantationalLevel);
  //       formData.append("bracketStyle", data.bracketStyle);

  //       formData.append("csvHeaderStatus", data.csvHeaderStatus);
  //       formData.append("emptyValuesOption", data.emptyValuesOption);
  //       formData.append("csvDelimiter", data.csvDelimiter);
  //       formData.append("csvQuoteCharacter", data.csvQuoteCharacter);
        

  //       try {
  //         axios
  //           .post(`${process.env.REACT_APP_BACKEND_URL}/converter/csv-to-json`, formData, config)
  //           .then((res) => {
  //             console.log("response", res);
  //             if (showFormatNewWindow) {
  //               setShowEditor(false);
  //             } else {
  //               setShowEditor(true);
  //             }
  //             // setFormattedJsonValue(res.data.data);
  //             // setFormattedJsonValue(JSON.parse(res?.data?.data));
  //                if (indentationLevel == 10 || indentationLevel == 9) {
  //                  console.log("I am from IF");
  //                  setFormattedJsonValue(res?.data?.data);
  //                } else {
  //                  console.log("i am from else");
  //                  setFormattedJsonValue(JSON.parse(res?.data?.data));
  //                }
  //             setSuccess(true);
  //             setSuccessMessage("Conversion Done Successfully!");
  //             setError(false);
  //             document.getElementById("editor_main_id")?.scrollIntoView({
  //               behavior: "smooth",
  //               block: "start",
  //             });
  //                reset();
  //           })
  //           .catch((error) => {
  //             console.error("API request failed:", error);
  //             if (error.response && error.response.data) {
  //               // Display the error message from the API response
  //               setError(true);
  //                 setShowGenerativeAiButton(true);
  //               setSuccess(false);

  //               setErrorMessage(error.response.data);
  //             } else {
  //               // Display a generic error message
  //               setError(true);
  //                 setShowGenerativeAiButton(true);
  //               setSuccess(false);

  //               setErrorMessage(error.response.data);
  //             }
  //           });
  //       } catch (error) {
  //         console.log("error1", error);
  //         // toast.warning("Something went wrong!");
  //       }
  //     } else {
  //       try {
        
  //         axios
  //           .post(`${process.env.REACT_APP_BACKEND_URL}/converter/csv-to-json`, data)
  //           .then((res) => {
  //             console.log("response", res);
  //             if (showFormatNewWindow) {
  //               setShowEditor(false);
  //             } else {
  //               setShowEditor(true);
  //             }
  //             // setFormattedJsonValue(JSON.parse(res?.data?.data));
  //                if (indentationLevel == 10 || indentationLevel == 9) {
  //                  console.log("I am from IF");
  //                  setFormattedJsonValue(res?.data?.data);
  //                } else {
  //                  console.log("i am from else");
  //                  setFormattedJsonValue(JSON.parse(res?.data?.data));
  //                }
  //            setSuccess(true);
  //            setSuccessMessage("Conversion Done Successfully!");
  //             setError(false);

  //             document.getElementById("editor_main_id")?.scrollIntoView({
  //               behavior: "smooth",
  //               block: "start",
  //             });
  //                reset();
  //             // }
  //           })
  //           .catch((error) => {
  //             console.error("API request failed:", error);
  //             if (error.response && error.response.data) {
  //               // Display the error message from the API response
  //               setError(true);
  //                 setShowGenerativeAiButton(true);
  //               setSuccess(false)
  //               setErrorMessage(error.response.data);
  //             } else {
  //               // Display a generic error message
  //               // toast.error("An error occurred while processing your request.");
  //             }
  //           });
  //       } catch (error) {
  //         console.log("error2", error);
  //         toast.warning("Something went wrong!");
  //       }
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };


  const onSubmit = async (data) => {
    console.log("submitedDAtaJsonFormatter", data);
    setFormattedJsonValue("");

    try {
      if (data?.inputCSVData && data?.inputCsvFile) {
        toast.warning("You need to upload file or use copy Paste only");
      } else if (data?.inputCsvFile) {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        let formData = new FormData();

        formData.append("inputCsvFile", data.inputCsvFile);
        formData.append("fileEncoding", data.fileEncoding);
        formData.append("incantationalLevel", data.incantationalLevel);
        formData.append("bracketStyle", data.bracketStyle);
        formData.append("csvHeaderStatus", data.csvHeaderStatus);
        formData.append("emptyValuesOption", data.emptyValuesOption);
        formData.append("csvDelimiter", data.csvDelimiter);
        formData.append("csvQuoteCharacter", data.csvQuoteCharacter);

        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/converter/csv-to-json`,
            formData,
            config
          );
          console.log("response", res);

          if (showFormatNewWindow) {
            setShowEditor(false);
          } else {
            setShowEditor(true);
          }

          const formattedJson =
            indentationLevel === 10 || indentationLevel === 9
              ? res?.data?.data
              : JSON.parse(res?.data?.data);
          setFormattedJsonValue(formattedJson);

          setSuccess(true);
          setSuccessMessage("Conversion Done Successfully!");
          setError(false);
          document.getElementById("editor_main_id")?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          // reset();
        } catch (error) {
          console.error("API request failed:", error);
          handleApiError(error);
        }
      } else {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/converter/csv-to-json`,
            data
          );
          console.log("response", res);

          if (showFormatNewWindow) {
            setShowEditor(false);
          } else {
            setShowEditor(true);
          }

          const formattedJson =
            indentationLevel === 10 || indentationLevel === 9
              ? res?.data?.data
              : JSON.parse(res?.data?.data);
          setFormattedJsonValue(formattedJson);

          setSuccess(true);
          setSuccessMessage("Conversion Done Successfully!");
          setError(false);
          document.getElementById("editor_main_id")?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          // reset();
        } catch (error) {
          console.error("API request failed:", error);
          handleApiError(error);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleApiError = (error) => {
    if (error.response && error.response.data) {
      // Display the error message from the API response
      setError(true);
      setShowGenerativeAiButton(true);
      setSuccess(false);

      // Ensure errorMessage is a string
      const errorMessage =
        typeof error.response.data === "object" &&
        error.response.data.errorMessage
          ? error.response.data.errorMessage
          : JSON.stringify(error.response.data);
      setErrorMessage(errorMessage);
    } else {
      // Display a generic error message
      setError(true);
      setShowGenerativeAiButton(true);
      setSuccess(false);
      setErrorMessage("An error occurred while processing your request.");
    }
  };

  

  const XMLtoHTMLConverter = ({ xml }) => {
    console.log("xml", xml);
    // Convert XML to HTML
    const convertToHtml = (xml) => {
      // Your XML to HTML conversion logic here
      return `<div>${xml}</div>`; // For demonstration, just wrapping XML in a <div>
    };

    const htmlContent = convertToHtml(xml);
    console.log("htmlContent", htmlContent);
    // Get the navigate function for programmatic navigation

    // Navigate to a new page with the converted HTML content
    // navigate("/html-page", { state: { htmlContent } });
    <Link
      to={{
        pathname: "/converted",
        state: { htmlContent: formattedJsonValue },
      }}
      target="_blank" // Open the link in a new tab
    ></Link>;

    // This component doesn't render anything directly as it's just for conversion and navigation
    return null;
  };

  const handleClick = () => {
    setRedirectToHtmlPage(true);
  };

  const handleButtonClick = () => {
    const newTab = window.open("", "_blank");

    const htmlContent = `
        <!DOCTYPE html>
        <html>
        <head>
            <title>XML Converted Page</title>
        </head>
        <body>
        <XMLDisplay/>
            <pre>${formattedJsonValue}</pre>
        </body>
        </html>
    `;

    newTab.document.write(<XMLtoHTMLConverter xml={formattedJsonValue} />);
  };

  console.log("errors", errors);
  const CustomInputContent = () => {
    const fileInputRef = useRef(null);

    const [file, setFile] = useState("");
    let fileName;
    const handleBrowseClick = () => {
      fileInputRef.current.click(); // Trigger click event on file input
    };

    const handleFileChange = (e) => {
      // Handle file change event here
      const selectedFile = e.target.files[0];
      console.log("Selected,file", selectedFile.name);
      fileName = selectedFile.name;
      setValue("inputCsvFile", selectedFile);
      setFile(selectedFile?.name);
      setInputJsonValueFile(selectedFile);
    };
    console.log("fileee", file);

    if (redirectToHtmlPage) {
      return <XMLtoHTMLConverter xml={formattedJsonValue} />;
    }

    return (
      <div className="main-container">
        <div className="custom-dnd">
          <img src={UploadIcon} className="uploadIcon" alt="upload-icon" />

          <h6 className="custom-input-text">
            Choose a file
          </h6>
        </div>
        <div>
          <button
            type="button"
            onClick={handleBrowseClick}
            className="browse-file-button"
          >
            Browse File
          </button>
          <input
            accept=".csv"
            type="file"
            multiple={false}
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
        {file && <h6 className="custom-input-text">{file}</h6>}
      </div>
    );
  };
  const fileEncodingOptions = [
    {
      label: "UTF-8",
      value: "UTF-8",
    },
    {
      label: "UTF-16",
      value: "UTF-16",
    },
  ];
  const csvQuoteCharacter = [
    {
      label: "Single quote (')",
      value: "SINGLE_QUOTE",
    },
    {
      label: `Double quote (")`,
      value: "DOUBLE_QUOTE",
    },
  ];
  const emptyvaluesOptions = [
    {
      label: "null",
      value: "null",
    },
    {
      label: `Empty strings (key:"")`,
      value: "emptyValue",
    },
  ];
  const jsonBracketStyle = [
    {
      label: "Collapsed (braces on same line)",
      value: "COLLAPSE",
    },
    {
      label: "Expanded (braces on different line)",
      value: "EXPAND",
    },
  ];
  const indentationLevelOptions = [
    {
      label: "2 spaces per indent level",
      value: "TWO_SPACES",
    },
    {
      label: "3 spaces per indent level",
      value: "THREE_SPACES",
    },
    {
      label: "4 spaces per indent level",
      value: "FOUR_SPACES",
    },
    {
      label: "Tab delimited",
      value: "TABS",
    },
    {
      label: "Compact (1 line)",
      value: "COMPACT",
    },
  ];
  const csvfileContainsOptions = [
    {
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
     
  ];
  const CSVDelimiterCharacter = [
    {
      label: "Comma(,)",
      value: ",",
    },
    {
      label: "Colon (:)",
      value: ":",
    },
    {
      label: "Semicolon (;)",
      value: ";",
    },
    {
      label: "Dot (.)",
      value: ".",
    },
    {
      label: "Tabs (\t)",
      value: "\t",
    },
  ];

   const styles = {
     option: (provided, state) => ({
       ...provided,
       fontSize: "14px",
     }),
     option: (styles, { data, isDisabled, isFocused, isSelected }) => {
       // const color = chroma(data.color);

       return {
         ...styles,
         // backgroundColor: isFocused ? "#c00eae" : isSelected ? "#c00eae" : null,
         // backgroundColor: isDisabled
         //   ? undefined
         //   : isSelected
         //   ? "#c00eae"
         //   : isFocused
         //   ? "$c00eae"
         //   : undefined,
         backgroundColor: isDisabled
           ? undefined
           : isFocused
           ? "#BF66B6"
           : isSelected
           ? "#c00eae"
           : undefined,
         // backgroundColor: isDisabled
         //   ? undefined
         //   : isFocused || isSelected
         //   ? "#c00eae"
         //   : undefined,
         // color: isFocused || isSelected ? "#fff" : "#000",
         color: isDisabled
           ? "#ccc"
           : isFocused || isSelected
           ? "white"
           : data.color,
         ":active": {
           ...styles[":active"],
           backgroundColor: !isDisabled
             ? isSelected
               ? "#c00eae"
               : "#BF66B6"
             : undefined,
         },
       };
     },
   };
   

  const [fileEncodingValue, setFileEncodingValue] = useState();
  const [indentationLevelValue, setIndentationLevelValue] = useState();
    const [emptyValue, setEmptyValue] = useState();
  const [csvQuoteCharacterValue, setCsvQuoteCharacterValue] = useState();
  const [CSVDelimitier , setCSVDelimiter] = useState();
  const [csvHeaderValue, setCsvHeader] = useState();
  const [bracketStyleValue, setBracketStyleValue] = useState();
 const [showGenerativeAiButton, setShowGenerativeAiButton] = useState(false);

    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
  const [inputJsonValue, setInputJsonValue] = useState();
  const [inputJsonValueFile, setInputJsonValueFile] = useState();
  const [formattedJsonValue, setFormattedJsonValue] = useState("");
  const [showEditor, setShowEditor] = useState(false);
  const [showFormatNewWindow, setShowFormatNewWindow] = useState(false);
  
  const [isFileDropped, setIsFileDropped] = useState(false);

  const handleChangeStatus = (fileStatus) => {
    if (fileStatus === "done") {
      setIsFileDropped(true);
    } else {
      setIsFileDropped(false);
    }
  };
  const getUploadParams = ({ file }) => {
    const body = new FormData();
    setValue("inputCsvFile", file);
    setInputJsonValueFile(file);

    body.append("dataFiles", file);
    return { url: "http://localhost:3000/uploadmultifile", body };
  };

  // preview component
  const Preview = ({ meta }) => {
    const { name, size, percent, status, previewUrl } = meta;
    console.log("dropZOneFile.meta", meta);
    return (
      <div className="preview-box">
        <span className="name">{name}</span> -{" "}
        <span className="status">{size}KB</span>
      </div>
    );
  };

  
  const [loading, setLoading] = useState(false);

  const fetchSolution = async () => {
    let inputJson = "";
    try {
      if (inputJsonValueFile) {
        setLoading(true);
        console.log("inputJsonValueFile", inputJsonValueFile);
        const file = inputJsonValueFile;
        const reader = new FileReader();
        reader.onload = async (e) => {
          inputJson = e.target.result;
          console.log("inputJson", inputJson);
          const solution = await getSolution(
            `Correct this CSV and then convert that correct CSV into JSON and return only JSON ${inputJson}`
          );
          setFormattedJsonValue(JSON.parse(solution));
          setLoading(false);
          setError(false);
        };
        reader.readAsText(file);
      } else {
        setLoading(true);

        // If no file is provided, use a default question or handle the case accordingly
        inputJson = inputJsonValue;
        console.log("inputJson", inputJson);
        const solution = await getSolution(
          `Correct this CSV and then convert that correct CSV into JSON and return only JSON  ${inputJsonValue}`
        );
        // console.log("solution", solution);

        setFormattedJsonValue(JSON.parse(solution));
        setLoading(false);
        setError(false);
      }

 
    } catch (error) {
      console.error("Error fetching solution:", error);
      document.getElementById("editor_main_id")?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  return (
    <div className="container-right mt-5 mt-md-0">
      <Helmet>
        <meta charset="UTF-8" />

        <title>Best CSV to JSON Converter Online | SDE Tools</title>
        <meta
          name="description"
          content="Transform CSV data into clean JSON format with SDE Tools' free online CSV to JSON converter. Simple and easy-to-use CSV to JSON converter online. Try now!"
        />
        <meta
          name="keywords"
          content="CSV to JSON Converter, CSV to JSON online converter"
        />

        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://sdetools.io/converters/csv-json-converter"
        />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="SDE Tools" />
        <meta
          property="og:title"
          content="Best CSV to JSON Converter Online | SDE Tools"
        />

        <meta
          property="og:description"
          content="Transform CSV data into clean JSON format with SDE Tools' free online CSV to JSON converter. Simple and easy-to-use CSV to JSON converter online. Try now!"
        />
        <meta
          property="og:url"
          content="https://sdetools.io/converters/csv-json-converter"
        />
      </Helmet>

      {/* {error && (
        <>
          <div className="error-message">
            <button className="closeButton">
              <MdOutlineClose onClick={() => setError(false)} />
            </button>
            {errorMessage}
          </div>
          {showGenerativeAiButton && (
            <div className="format-button-generative-ai ">
              <button
                className="format-btn format-button-generative-ai"
                onClick={fetchSolution}
                type="button"
              >
                Convert Using Generative Ai
              </button>
            </div>
          )}
        </>
      )} */}

      {error && (
        <>
          <div className="error-message">
            <div className="row">
              <div className="col-8">{errorMessage}</div>
              <div className="col-4">
                <div className="row">
                  <div className="col-10 gen-ai-button">
                    {showGenerativeAiButton && (
                      <button
                        className="btn-hover color-11"
                        onClick={fetchSolution}
                        type="button"
                      >
                        <BsStars
                          className="mr-2"
                          style={{
                            marginRight: "8px",
                            transform: "rotate(-90deg)",
                            transition: "transform 0.3s ease-in-out", // Optional: adds smooth rotation effect
                          }}
                          size={25}
                        />
                        Smart Fix
                      </button>
                    )}
                  </div>
                  <div className="col-2">
                    {" "}
                    <button className="closeButton">
                      <MdOutlineClose onClick={() => setError(false)} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="error-message d-none">
            <button className="closeButton">
              <MdOutlineClose onClick={() => setError(false)} />
            </button>
            {errorMessage}
          </div>
          {showGenerativeAiButton && (
            <div className="format-button-generative-ai  d-none">
              {/* <button
                className="format-btn format-button-generative-ai"
                onClick={fetchSolution}
                type="button"
              >
               <BsStars /> Format JSON Using Generative Ai
              </button>*/}
              <button
                className="btn-hover color-11"
                onClick={fetchSolution}
                type="button"
              >
                <BsStars size={25} />
                Smart Fix
              </button>
            </div>
          )}
        </>
      )}
      <br />
      {success && (
        <div className="success-message ">
          <button className="closeButton">
            <MdOutlineClose onClick={() => setSuccess(false)} />
          </button>
          {successMessage}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="card-formatter border border-0 card rounded-md bg-white shadow shadow-sm">
          <div className="row">
            <div className="col-12 col-md-5 mobile-responsive-col left-container-padding">
              <div className="copy-paste">
                <label>Copy-paste your CSV here</label>
                <textarea
                  onChange={(e) => {
                    setInputJsonValue(e.target.value);
                    setValue("inputCSVData", e.target.value);
                  }}
                  placeholder="Copy-paste your CSV here"
                  className="text-area-formatter-new"
                />
              </div>

              <div className="button-section-2 row d-flex d-none justify-center  ">
                <div className="col-span-1 mb-2  col-12 col-md-6  py-2 text-center">
                  <button
                    className="format-btn"
                    onClick={() => setShowFormatNewWindow(false)}
                    type="submit"
                  >
                    Convert CSV to JSON
                  </button>
                </div>
                <div className="col-12 d-none col-md-6 mobile-responsive-view-btn-container">
                  <button
                    onClick={() => {
                      if (formattedJsonValue !== "") {
                        handleClick();

                        setShowFormatNewWindow(true);
                      }
                    }}
                    type="submit"
                    className="col-span-1 format-btn  py-2 text-center"
                  >
                    Convert CSV to JSON in new window
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-2 mobile-responsive-col column-2-center left-container-padding">
              <div className="copy-paste mt-4">
                <label>Upload CSV file</label>
              </div>
              <div>
                <Dropzone
                  classNames="dropzone-container"
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  styles={{
                    dropzone: {
                      overflow: "auto",
                      border: "1px dashed  #C6C3C3",
                      background: "#f5f5f5",
                      width: "100%",
                      height: "145px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                    inputLabelWithFiles: { margin: "20px 3%" },
                  }}
                  canRemove={true}
                  maxFiles={1}
                  PreviewComponent={Preview}
                  accept=".csv"
                  inputContent={<CustomInputContent />}
                />
              </div>

              <div className="col-span-1  col-12 col-md-12  py-2 text-center">
                <button className="format-btn" type="submit">
                  Convert
                </button>
              </div>
            </div>
            <div className="col-12 col-md-5 mobile-responsive-col">
              <div className="row mobile-res-row" id="editor_main_id">
                <div className="col-8">
                  <h6 className="formatted-heading">Converted JSON</h6>
                </div>

                <div className="editor_holder_button col-4">
                  <button
                    onClick={copyJson}
                    type="button"
                    className="editor_holder_button_bg"
                  >
                    <BiCopyAlt />
                  </button>
                  <button
                    onClick={() => {
                      if (formattedJsonValue != "") {
                        downloadJson();
                      }
                    }}
                    type="button"
                    className="editor_holder_button_bg ms-3"
                  >
                    <RiFileDownloadFill />
                  </button>
                </div>
              </div>
              <div
                 
                className={`editor-border-new ${
                  formattedJsonValue ? "editor-scroll-enable" : ""
                }`}
              >
                {formattedJsonValue && (
                  <div className="max-h-60   react-json-editor ">
                    {indentationLevel != 10 && indentationLevel != 9 ? (
                      <ReactJson
                        displayArrayKey={false}
                        enableClipboard={false}
                        displayObjectSize={false}
                        displayDataTypes={false}
                        indentWidth={indentationLevel || 3}
                        src={formattedJsonValue}
                        collapsed={bracketStylesView}
                        name={false}
                      />
                    ) : (
                      <Editor
                        value={formattedJsonValue}
                        onValueChange={(code) => setFormattedJsonValue(code)}
                        highlight={(code) => highlight(code, languages.js)}
                        padding={10}
                        readOnly
                        style={{
                          fontFamily: '"Fira code", "Fira Mono", monospace',
                          fontSize: 12,
                          // border: "2px solid ",
                          borderColor: "#e4e4e4",
                          borderRadius: "10px",
                          minHeight: "80%",
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
              {/* <Editor
                value={formattedJsonValue}
                onValueChange={(code) => setFormattedJsonValue(code)}
                highlight={(code) => highlight(code, languages.js)}
                padding={10}
                disabled
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                  border: "2px solid ",
                  borderColor: "#e4e4e4",
                  borderRadius: "10px",
                  minHeight: "80%",
                }}
              />*/}
            </div>
            <div className="button-section row">
              <div className="col-span-1 copy-paste col-12 col-md-4  bg-gray-200 py-2 text-start">
                <label className="label-drop-down">File Encoding</label>
                <Select
                  options={fileEncodingOptions}
                  isClearable={false}
                  styles={styles}
                  defaultValue={fileEncodingOptions[0]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setFileEncodingValue(e);
                    // setCourseName(e);
                    // const selectedValues = e.map((option: any) => option.value);
                    // console.log("selectedValues", selectedValues);
                    setValue("fileEncoding", e.value);
                  }}
                  classNamePrefix="select"
                  value={fileEncodingValue}
                />
              </div>
              <div className="col-span-1 copy-paste col-12 col-md-4 bg-gray-200 py-2 text-start">
                <label className="label-drop-down">
                  CSV file contains a header
                </label>
                <Select
                  options={csvfileContainsOptions}
                  isClearable={false}
                  styles={styles}
                  defaultValue={csvfileContainsOptions[0]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setCsvHeader(e);
                    // setCourseName(e);
                    // const selectedValues = e.map((option: any) => option.value);
                    // console.log("selectedValues", selectedValues);
                    // setValue("courseName", selectedValues);
                    setValue("csvHeaderStatus", e.value);
                  }}
                  classNamePrefix="select"
                  value={csvHeaderValue}
                />
              </div>
              <div className="col-span-1 col-12 col-md-4  copy-paste bg-gray-200 py-2 text-start">
                <label className="label-drop-down">
                  CSV Delimiter character
                </label>
                <Select
                  options={CSVDelimiterCharacter}
                  isClearable={false}
                  styles={styles}
                  defaultValue={CSVDelimiterCharacter[0]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setCSVDelimiter(e);

                    setValue("csvDelimiter", e.value);
                  }}
                  classNamePrefix="select"
                  value={CSVDelimitier}
                />
              </div>
              <div className="col-span-1 col-12 copy-paste  col-md-4 bg-gray-200 py-2 text-start">
                <label className="label-drop-down">CSV Quote character</label>
                <Select
                  options={csvQuoteCharacter}
                  isClearable={false}
                  styles={styles}
                  defaultValue={csvQuoteCharacter[1]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setCsvQuoteCharacterValue(e);
                    // setCourseName(e);
                    // const selectedValues = e.map((option: any) => option.value);
                    // console.log("selectedValues", selectedValues);
                    // setValue("courseName", selectedValues);
                    setValue("csvQuoteCharacter", e.value);
                  }}
                  classNamePrefix="select"
                  value={csvQuoteCharacterValue}
                />
              </div>
              <div className="col-span-1 col-12 col-md-4  copy-paste bg-gray-200 py-2 text-start">
                <label className="label-drop-down">Empty values</label>
                <Select
                  options={emptyvaluesOptions}
                  isClearable={false}
                  styles={styles}
                  defaultValue={emptyvaluesOptions[0]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setEmptyValue(e);

                    setValue("emptyValuesOption", e.value);
                  }}
                  classNamePrefix="select"
                  value={emptyValue}
                />
              </div>

              <div className="col-span-1 col-12 col-md-4  copy-paste bg-gray-200 py-2 text-start">
                <label className="label-drop-down">
                  JSON indentation level
                </label>
                <Select
                  options={indentationLevelOptions}
                  isClearable={false}
                  styles={styles}
                  defaultValue={indentationLevelOptions[1]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setIndentationLevelValue(e);

                    setValue("incantationalLevel", e.value);
                    setFormattedJsonValue("");
                    setSuccess(false);
                    setValue("incantationalLevel", e.value);
                    switch (e.value) {
                      case "TWO_SPACES":
                        setIndentationLevel(2);
                        break;
                      case "THREE_SPACES":
                        setIndentationLevel(3);
                        break;
                      case "FOUR_SPACES":
                        setIndentationLevel(4);
                        break;
                      case "TABS":
                        setIndentationLevel(8);
                        break;
                      case "COMPACT":
                        setIndentationLevel(9);
                        break;
                      case "JAVASCRIPT":
                        setIndentationLevel(10);
                        break;
                      default:
                        setIndentationLevel(3);
                        break;
                    }
                  }}
                  classNamePrefix="select"
                  value={indentationLevelValue}
                />
              </div>
              <div className="col-span-1 col-12 col-md-4 copy-paste bg-gray-200 py-2 text-start">
                <label className="label-drop-down">JSON Brace style</label>
                <Select
                  options={jsonBracketStyle}
                  isClearable={false}
                  styles={styles}
                  defaultValue={jsonBracketStyle[1]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setBracketStyleValue(e);

                    setValue("bracketStyle", e.value);
                    switch (e.value) {
                      case "COLLAPSE":
                        setBracketStylesView(true);
                        break;
                      case "EXPAND":
                        setBracketStylesView(false);
                        break;

                      default:
                        setBracketStylesView(true);
                        break;
                    }
                  }}
                  classNamePrefix="select"
                  value={bracketStyleValue}
                />
              </div>
            </div>
          </div>
        </Card>
      </form>

      <div className="main-content-container">
        <div className="sub-heading">
          <div className="heading-formatter">
            <h1 className="heading-formatter-text">
              Best CSV to JSON Converter Online
            </h1>
            <p className="heading-paragraph">
              Bridge the gap between spreadsheets and applications with our free
              online CSV to JSON Converter! The CSV to JSON Converter easily
              converts Comma-Separated Values (CSV) data into a properly
              structured JSON (JavaScript Object Notation) document. The CSV
              file's rows and columns are analyzed and if the header row is
              present, keys are defined based on it. Corresponding JSON objects
              are then formed accordingly.
            </p>
          </div>
          <h2 className="main-heading">
            Benefits of Our CSV to JSON Converter
          </h2>
          <ul className="sub-list-ul">
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
                className="bold"
              >
                Effortless Conversion:
              </h3>
              Simply upload your CSV file or paste its content into the
              designated input field. Our tool takes care of the rest,
              transforming your data into clean and organized JSON format in
              seconds!
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Intelligent Data Mapping:
              </h3>
              No need to manually configure complex settings. Our converter
              intelligently analyzes your CSV data, automatically identifying
              headers and mapping columns to corresponding JSON keys.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Flexibility and Control:
              </h3>
              While our converter prioritizes accuracy, you also have the option
              to customize certain aspects of the conversion process, such as
              defining custom delimiters or handling empty values. This allows
              you to fine-tune the output JSON to fit your specific needs.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Enhanced Data Analysis:
              </h3>
              By converting your CSV data to JSON, you can leverage the power of
              various data analysis tools and libraries that work natively with
              JSON. This facilitates deeper insights and more efficient data
              exploration.
            </li>
          </ul>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">What is CSV to JSON Converter?</h6>
          <p className="main-heading-paragraph">
            Tabular data can be saved in CSV, a straightforward text format, but
            it doesn't have the same structure as JSON. JSON provides a more
            organized and adaptable method. This tool fills the void by
            converting CSV data into an equivalent JSON file. Using key-value
            strings to construct JSON items, it extracts points of data from the
            CSV file and assesses its column and row data.
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">Why use CSV to JSON Converter?</h6>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>It transforms CSV data into well-structured JSON format.</li>
              <li>
                As compared to CSV, JSON is frequently considered to be an
                easier to use and lighter format. Speeding up data processing,
                particularly when dealing with big datasets, can be achieved by
                converting CSV to JSON.
              </li>
              <li>
                Comparatively speaking to CSV, JSON makes data management and
                analysis simpler. Using the capabilities of JSON, you may
                analyze and explore data further with the help of this
                converter.
              </li>
            </ul>
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">
            Why use SDE Tools Validator and Formatter?
          </h6>
          <p className="main-heading-paragraph">
            It automatically formats your code in accordance with the coding
            standards. SDE Tools offers you various advantages:
          </p>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                It helps in increasing the productivity as it provides you
                features like code completion and highlighting the syntax
                minimizing errors.
              </li>
              <li>
                To fit your coding style, the editor lets you adjust formatting
                choices and handles several file encodings.
              </li>

              <li>
                It enhances the readability by providing clarity to all the
                developers.
              </li>
            </ul>
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">How does a SDE Tools Editor work?</h6>
          <p className="main-heading-paragraph">
            The validator functions similarly to a computerized code reviewer.
            It goes line by line through your code. It checks:
          </p>
          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                Mistakes such as mismatched braces, improper function calls, and
                misplaced semicolons.
              </li>
              <li>
                The coding style differentiation such as indentation spaces.
              </li>

              <li>
                A report is provided by the validator which includes identified
                problems and enables you to fix those problems.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CSVToJSON;
