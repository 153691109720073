import React, { useState } from 'react'
import "./sidebar.css";
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
const SubMenu = ({
  data,
  isSidebarOpen,
  toggleSidebar,
  subnav ,toggleSubMenu,
}) => {
  // const [subnav, setSubnav] = useState(false);

  console.log("isSidebarOpen", isSidebarOpen);
  // const showSubnav = () => setSubnav(!subnav);

  const location = useLocation();
  console.log("data.subNav", data.subNav);
  console.log("data.toggleSubMenu", toggleSubMenu);
  return (
    <>
      {isSidebarOpen ? (
        <div className="main-container-sub-menu" >
          {" "}
          <div
            onClick={data.subNav && toggleSubMenu}
            className={`sub-menu-main container  ${subnav ? "active" : ""}`}
          >
            <div className={`submenu-container 10  `}>
              <span className="icon">{data.icon}</span>
              <span className="sub-menu-heading_main">{data.title}</span>
            </div>
            <div className="side-bar-nav-icons col-2">
              {data.subNav && subnav
                ? data.iconOpened
                : data.subNav
                ? data.iconClosed
                : null}
            </div>
            <div className="sub-menu-links"></div>
          </div>
          <div  className={`sub-menu-main_links  ${subnav ? "active" : ""}`}>
            {subnav &&
              data.subNav.map((item, index) => {
                const isActive = location.pathname === item.path;
                const linkClassName = isActive
                  ? "sub-menu-items active"
                  : "sub-menu-items";
                return (
                  <Link
                    onClick={toggleSidebar}
                    className={linkClassName}
                    to={item.path}
                    key={index}
                  >
                    <span className="sub-icons">{item.icon}</span>
                    <h6 className="sub-menu-heading">{item.title}</h6>
                  </Link>
                );
              })}
          </div>
        </div>
      ) : (
        <div className="main-container-collapse ">
          {" "}
          <div className="li-data "></div>
          <div className="main-menu ">
            <span className="icon row  flex-shrink-0 collapse-icon collapse-inside-icon menu-section-title text-gray ff-heading fs-16 fw-bold text-uppercase">
              <span className="nav-icon col-2"> {data?.icon}</span>
              <span className="submenu-opener col-10 ">{data?.title}</span>
            </span>
            <ul className="sub-menu">
              {data?.subNav?.map((item, index) => {
                const isActive = location.pathname === item.path;
                const linkClassName = isActive
                  ? "sub-menu-items active"
                  : "sub-menu-items";

                return (
                  <li className="menu-item" key={index}>
                    <Link className={`row ${linkClassName}`} to={item.path}>
                      <span className="sub-icons col-3 ">{item.icon}</span>
                      <h6 className="sub-menu-heading ml-collapse col-9 text-start">
                        {item.title}
                      </h6>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default SubMenu