/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "./generator.css";
import Card from "react-bootstrap/Card";
import { Formik, Field } from "formik";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import ReactJson from "react-json-view";

import MonacoEditor from "react-monaco-editor";
import UploadIcon from "../../assets/DND/upload.png";
import Select from "react-select";
import axios from "axios";
import { BiCopyAlt } from "react-icons/bi";
import { RiFileDownloadFill } from "react-icons/ri";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";

const LoremIpsum = () => {
  // form validation
  const FormValidationSchema = yup.object({
    ipsumType: yup.string().notRequired("Ipsum type is required"),
    itemsToGenerate: yup.mixed().notRequired("Items to generate is required"),
    numberOfItems: yup.string().notRequired("Number of items is required"),

    itemsLength: yup.string().notRequired("Items Length is required"),
    includeHtmlMakeup: yup
      .string()
      .notRequired("Include html markup is required"),
  });
  let parseJSON;

  const {
    register,
    reset,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      ipsumType: "LOREMIPSUM",
      itemsToGenerate: "PARAGRAPHS",
      numberOfItems: "5",

      itemsLength: "SMALL",
      includeHtmlMakeup: "false",
    },
    resolver: yupResolver(FormValidationSchema),
  });
  const bracketStyleOptions = [
    {
      label: "Collapsed (braces on same line)",
      value: "COLLAPSE",
    },
    {
      label: "Expanded (braces on different line)",
      value: "EXPAND",
    },
  ];
  const [indentationLevel, setIndentationLevel] = useState(3);
  const [bracketStylesView, setBracketStylesView] = useState(false);
  const copyJson = () => {
    navigator.clipboard
      .writeText(JSON.stringify(formattedJsonValue, null, indentationLevel))
      .then(
        () => {
          if (formattedJsonValue != "") {
            toast.success("JSON copied to clipboard");
          }
        },
        () => {
          if (formattedJsonValue != "") {
            toast.warning("Failed to copy JSON to clipboard");
          }
        }
      );
  };

  const downloadJson = () => {
    const jsonBlob = new Blob([formattedJsonValue], {
      type: "application/txt",
    });
    const url = URL.createObjectURL(jsonBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "FormattedJSON.txt";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    if (url) {
      toast.success("File downloaded successfully!");
    }
  };

  const onSubmit = async (data) => {
    console.log("submitedDAtaJsonFormatter", data);
    setFormattedJsonValue("");

   try {
     const formatData = {
       ipsumType: data.ipsumType,
       itemsToGenerate:data?.itemsToGenerate,
       numberOfItems: data?.numberOfItems,
       itemsLength: data?.itemsLength,
       includeHtmlMakeup: data?.includeHtmlMakeup ==="true"?true:false,
     };

     axios
       .post(
         `${process.env.REACT_APP_BACKEND_URL}/generator/lorem-ipsum`,
         formatData
       )
       .then((res) => {
         console.log("Response:", res);
         setFormattedJsonValue(res?.data?.data);

         // Set success state and message, reset error, scroll to editor
         setSuccess(true);
         setSuccessMessage("Lorem Ipsum Generated Successfully!");
         setError(false);
        
         document.getElementById("editor_main_id")?.scrollIntoView({
           behavior: "smooth",
           block: "start",
         });
       })
       .catch((error) => {
         console.error("API request failed:", error);
         if (error.response && error.response.data) {
           setError(true);
           setSuccess(false);
           setErrorMessage(error.response.data);
         } else {
           // Handle unexpected errors or network issues
           setError(true);
           setSuccess(false);
           setErrorMessage("Network error or server unreachable.");
         }
       });
   } catch (error) {
     console.error("Error:", error);
     // Handle unexpected errors that may occur within try block
   }

  };

  //   useEffect(() => {
  //     setValue("fileEncoding", "UTF-8");
  //     setValue("incantationalLevel", "THREE_SPACES");
  //     setValue("prefixAttributeWith", "");
  //     setValue("textPropertyName", "#text");
  //     setValue("bracketStyle", "COLLAPSE");
  //   }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleButtonClick = () => {
    const newTab = window.open("", "_blank");

    const htmlContent = `
        <!DOCTYPE html>
        <html>
        <head>
            <title>JSON ConvertedPage</title>
        </head>
        <body>
            <pre>${formattedJsonValue}</pre>
        </body>
        </html>
    `;

    newTab.document.write(htmlContent);
  };

  console.log("errors", errors);
  const CustomInputContent = () => {
    const fileInputRef = useRef(null);

    const [file, setFile] = useState("");
    let fileName;
    const handleBrowseClick = () => {
      fileInputRef.current.click(); // Trigger click event on file input
    };

    const handleFileChange = (e) => {
      // Handle file change event here
      const selectedFile = e.target.files[0];
      console.log("Selected,file", selectedFile.name);
      fileName = selectedFile.name;
      setValue("inputXmlFile", selectedFile);
      setFile(selectedFile?.name);
    };
    console.log("fileee", file);

    return (
      <div className="main-container">
        <div className="custom-dnd">
          <img src={UploadIcon} className="uploadIcon" alt="upload-icon" />

          <h6 className="custom-input-text">
            Choose a file or drag & drop it here
          </h6>
        </div>
        <div>
          <button
            type="button"
            onClick={handleBrowseClick}
            className="browse-file-button"
          >
            Browse File
          </button>
          <input
            accept=".xml"
            type="file"
            multiple={false}
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
        {file && <h6 className="custom-input-text">{file}</h6>}
      </div>
    );
  };
  const ipsumTypesOptions = [
    {
      label: "Lorem Ipsum",
      value: "LOREMIPSUM",
    },
    {
      label: "Batman Ipsum",
      value: "BATMANIPSUM",
    },
    {
      label: "Marvel Ipsum",
      value: "MARVELIPSUM",
    },
    {
      label: "Pokemon Ipsum",
      value: "POKEMONIPSUM",
    },
  ];

  const indentationLevelOptions = [
    {
      label: "Paragraphs",
      value: "PARAGRAPHS",
    },
    {
      label: "Sentences",
      value: "SENTENCES",
    },
    {
      label: "Words",
      value: "WORDS",
    },
    // {
    //   label: "List Items",
    //   value: "LIST_ITEMS",
    // },
  ];
  const itemLengthOptions = [
    {
      label: "Small",
      value: "SMALL",
    },
    {
      label: "Medium",
      value: "MEDIUM",
    },
    {
      label: "Large",
      value: "LARGE",
    },
  ];
  const HtmlOptions = [
    {
      label: "Yes",
      value: "true",
    },
    {
      label: "No",
      value: "false",
    },
  ];

  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        // backgroundColor: isFocused ? "#c00eae" : isSelected ? "#c00eae" : null,
        // backgroundColor: isDisabled
        //   ? undefined
        //   : isSelected
        //   ? "#c00eae"
        //   : isFocused
        //   ? "$c00eae"
        //   : undefined,
        backgroundColor: isDisabled
          ? undefined
          : isFocused
          ? "#BF66B6"
          : isSelected
          ? "#c00eae"
          : undefined,
        // backgroundColor: isDisabled
        //   ? undefined
        //   : isFocused || isSelected
        //   ? "#c00eae"
        //   : undefined,
        // color: isFocused || isSelected ? "#fff" : "#000",
        color: isDisabled
          ? "#ccc"
          : isFocused || isSelected
          ? "white"
          : data.color,
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#c00eae"
              : "#BF66B6"
            : undefined,
        },
      };
    },
  };

  const [fileEncodingValue, setFileEncodingValue] = useState();
  const [indentationLevelValue, setIndentationLevelValue] = useState();
  const [includeHtmlValue, setIncludeHtmlValue] = useState();
  const [itemLengthValue, setItemLengthValue] = useState();
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [bracketStyleValue, setBracketStyleValue] = useState();
  const [inputJsonValue, setInputJsonValue] = useState();
  const [inputJsonValueFile, setInputJsonValueFile] = useState();
  const [formattedJsonValue, setFormattedJsonValue] = useState("");
  const [showEditor, setShowEditor] = useState(false);
  const [showFormatNewWindow, setShowFormatNewWindow] = useState(false);

  const [isFileDropped, setIsFileDropped] = useState(false);

  const handleChangeStatus = (fileStatus) => {
    if (fileStatus === "done") {
      setIsFileDropped(true);
    } else {
      setIsFileDropped(false);
    }
  };
  const getUploadParams = ({ file }) => {
    const body = new FormData();
    setValue("inputXmlFile", file);
    setInputJsonValueFile(file);

    body.append("dataFiles", file);
    return { url: "http://localhost:3000/uploadmultifile", body };
  };

  // preview component
  const Preview = ({ meta }) => {
    const { name, size, percent, status, previewUrl } = meta;
    console.log("dropZOneFile.meta", meta);
    return (
      <div className="preview-box">
        <span className="name">{name}</span> -{" "}
        <span className="status">{size}KB</span>
      </div>
    );
  };

  return (
    <div className="container">
      {error && (
        <div className="error-message">
          <button className="closeButton">
            <MdOutlineClose onClick={() => setError(false)} />
          </button>
          {errorMessage}
        </div>
      )}
      {success && (
        <div className="success-message ">
          <button className="closeButton">
            <MdOutlineClose onClick={() => setSuccess(false)} />
          </button>
          {successMessage}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="card-formatter border border-0 card rounded-md bg-white shadow shadow-sm">
          <div className="row">
            <div className="col-12 col-md-6 mobile-responsive-col left-container-padding">
              <div className="button-section row">
                <div className="col-span-1 col-12 col-lg-6 copy-paste bg-gray-200  text-start">
                  <label className="label-drop-down">Ipsum type</label>
                  <Select
                    options={ipsumTypesOptions}
                    isClearable={false}
                    styles={styles}
                    defaultValue={ipsumTypesOptions[0]}
                    onChange={(e) => {
                      setFileEncodingValue(e);

                      setValue("ipsumType", e.value);
                    }}
                    classNamePrefix="select"
                    value={fileEncodingValue}
                  />
                </div>

                <div className="col-span-1 col-12 col-lg-6 copy-paste bg-gray-200 text-start">
                  <label className="label-drop-down">Items to generate</label>
                  <Select
                    options={indentationLevelOptions}
                    isClearable={false}
                    styles={styles}
                    defaultValue={indentationLevelOptions[1]}
                    onChange={(e) => {
                      setIndentationLevelValue(e);

                      setSuccess(false);
                      setValue("itemsToGenerate", e.value);
                    }}
                    classNamePrefix="select"
                    value={indentationLevelValue}
                  />
                </div>
                <div className="col-span-1 col-12 col-lg-6 copy-paste bg-gray-200 py-2 text-start">
                  <label className="label-drop-down">
                    No of items to generate
                  </label>
                  <input
                    type="number"
                    defaultValue={5}
                    onChange={(e) => {
                      setValue("numberOfItems", e.target.value);
                    }}
                    className="input-area-formatter"
                    placeholder="5"
                  />
                </div>
                <div className="col-span-1 col-12 col-lg-6 copy-paste bg-gray-200 py-2 text-start">
                  <label className="label-drop-down">Item length</label>
                  <Select
                    options={itemLengthOptions}
                    isClearable={false}
                    styles={styles}
                    defaultValue={itemLengthOptions[1]}
                    onChange={(e) => {
                      setItemLengthValue(e);

                      setValue("itemsLength", e.value);
                    }}
                    classNamePrefix="select"
                    value={itemLengthValue}
                  />
                </div>
                <div
                  style={{ width: "50%" }}
                  className="col-span-1 col-12  copy-paste bg-gray-200 color-[#C00EAE] py-2 text-start"
                >
                  Maximum of 50 paragraphs, 250 sentences, 5000 words
                </div>
                <div></div>
                <div
                  style={{ width: "100%" }}
                  className="col-span-1 col-12 col-lg-6 copy-paste bg-gray-200 py-2 text-start"
                >
                  <label className="label-drop-down">Include HTML markup</label>
                  <Select
                    options={HtmlOptions}
                    isClearable={false}
                    styles={styles}
                    defaultValue={HtmlOptions[1]}
                    onChange={(e) => {
                      setIncludeHtmlValue(e);

                      setValue("includeHtmlMakeup", e.value);
                    }}
                    classNamePrefix="select"
                    value={includeHtmlValue}
                  />
                </div>
              </div>
              <div className="button-section-2 row">
                <div className="col-span-1  col-12 col-md-6    py-2 text-center">
                  <button
                    onClick={() => setShowFormatNewWindow(false)}
                    type="submit"
                    className="format-btn "
                  >
                    Generate Lorem Ipsum
                  </button>
                </div>
                <div className="col-12 d-none col-md-6 mobile-responsive-view-btn-container">
                  <button
                    onClick={() => {
                      if (formattedJsonValue !== "") {
                        handleButtonClick();
                        setShowFormatNewWindow(true);
                      }
                    }}
                    type="submit"
                    className="col-span-1 format-btn  py-2 text-center"
                  >
                    Convert XML to JSON in new window
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mobile-responsive-col ">
              <div className="row mobile-res-row" id="editor_main_id">
                <div className="col-8">
                  <label className="label-drop-down mt-4"></label>
                  <h6 className="formatted-heading">Generated Sample</h6>
                </div>

                <div className="editor_holder_button col-4">
                  <button
                    onClick={copyJson}
                    type="button"
                    className="editor_holder_button_bg"
                  >
                    <BiCopyAlt />
                  </button>
                  <button
                    onClick={() => {
                      if (formattedJsonValue != "") {
                        downloadJson();
                      }
                    }}
                    type="button"
                    className="editor_holder_button_bg ms-3"
                  >
                    <RiFileDownloadFill />
                  </button>
                </div>
              </div>
              <div className="editor-border">
                {formattedJsonValue && (
                  <div className="max-h-60   react-json-editor ">
                    <Editor
                      value={formattedJsonValue}
                      onValueChange={(code) => setFormattedJsonValue(code)}
                      highlight={(code) => highlight(code, languages.js)}
                      padding={10}
                      readOnly
                      style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12,
                        // border: "2px solid ",
                        borderColor: "#e4e4e4",
                        borderRadius: "10px",
                        minHeight: "80%",
                      }}
                    />
                  </div>
                )}
              </div>
              {/*<Editor
                value={formattedJsonValue}
                onValueChange={(code) => setFormattedJsonValue(code)}
                highlight={(code) => highlight(code, languages.js)}
                padding={10}
                disabled
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                  border: "2px solid ",
                  borderColor: "#e4e4e4",
                  borderRadius: "10px",
                  minHeight: "80%",
                }}
              />*/}
            </div>
          </div>
        </Card>
      </form>

      <div className="main-content-container">
        <div className="sub-heading">
          <div className="heading-formatter">
            <h4 className="heading-formatter-text">Lorem Ipsum Generator</h4>
            <p className="heading-paragraph">
              This free lorem ipsum generator lets you choose how many
              sentences, paragraphs or list items you want. You can also select
              to include HTML markup and specify how big the text should be. It
              currently supports standard Lorem Ipsum, Marvel Ipsum, Batman
              Ipsum and Pokemon Ipsum.
            </p>
          </div>
          <br />
          <h6 className="main-heading">About the SDE Tools Editor</h6>
          <p className="main-heading-paragraph">
            The SDE Tools Editor is a flexible code editor designed specifically
            for software development. It offers various features and programming
            languages such as formatting tools integrations, highlighting
            syntax, and completion of code. Additionally, it also provides
            built-in integrated converters for different formats that helps you
            in exchange of your data by easily converting your data to various
            formats like JSON, XML, CSV, and YAML. SDE Tools Editor helps you in
            converting timestamps to date using Epoch and Unix Timestamp
            Converter. Now, with the assistance of QR Code Generator, you can
            easily generate QR Code by just providing the URL or text that you
            desire to encode. The seamless difference checker lets you quickly
            identify the difference between your input code enabling you to keep
            track of changes in the version.
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">What is XML to JSON Converter?</h6>
          <p className="main-heading-paragraph">
            Although they are distinct, JSON and XML are both widely used data
            formats. This converter fills the space by converting XML data into
            an equivalent JSON document. To produce a JSON format with key-value
            sets and stacked items, it scans the XML components, features, and
            their relationship to one another.
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">Why use XML to JSON Converter?</h6>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>It transforms XML data into well-structured JSON format.</li>
              <li>
                As compared to XML, JSON is frequently considered to be an
                easier to use and lighter format. In some cases, XML to JSON
                conversion can speed up data processing.
              </li>
              <li>
                This converter lets you transform XML data into the necessary
                JSON format for API consumption. Several APIs (Application
                Programming Interfaces) rely on JSON for data interchange.
              </li>
            </ul>
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">
            Why use SDE Tools Validator and Formatter?
          </h6>
          <p className="main-heading-paragraph">
            It automatically formats your code in accordance with the coding
            standards. SDE Tools offers you various advantages:
          </p>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                It helps in increasing the productivity as it provides you
                features like code completion and highlighting the syntax
                minimizing errors.
              </li>
              <li>
                To fit your coding style, the editor lets you adjust formatting
                choices and handles several file encodings.
              </li>

              <li>
                It enhances the readability by providing clarity to all the
                developers.
              </li>
            </ul>
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">How does a SDE Tools Editor work?</h6>
          <p className="main-heading-paragraph">
            The validator functions similarly to a computerized code reviewer.
            It goes line by line through your code. It checks:
          </p>
          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                Mistakes such as mismatched braces, improper function calls, and
                misplaced semicolons.
              </li>
              <li>
                The coding style differentiation such as indentation spaces.
              </li>

              <li>
                A report is provided by the validator which includes identified
                problems and enables you to fix those problems.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoremIpsum;
