/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "./formatter.css";
import Card from "react-bootstrap/Card";
import { Formik, Field } from "formik";
import { useForm, Controller } from "react-hook-form";
import chroma from "chroma-js";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactJson from "react-json-view";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import { JSONViewer } from "react-json-editor-viewer";
import * as yup from "yup";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { MdOutlineClose } from "react-icons/md";
import MonacoEditor from "react-monaco-editor";
import UploadIcon from "../../assets/DND/upload.png";
import Select from "react-select";
import axios from "axios";
import { BiCopyAlt } from "react-icons/bi";
import { RiFileDownloadFill } from "react-icons/ri";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { BsStars } from "react-icons/bs";
import {
  blockCommentFolding,
  markdownFolding,
  readOnlyCodeFolding,
} from "prism-code-editor/code-folding";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";

import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/mode-javascript";

// Optionally import other languages or themes you need
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-xml";
import "ace-builds/src-noconflict/ext-language_tools";
import { getSolution } from "./langchain";
import JsonPreview from "../../components/previewer/JsonPrevier";

const JsonFormatters = () => {
  // form validation
  const FormValidationSchema = yup.object({
    inputJsonData: yup.string().notRequired("Json data is required"),
    inputJsonFile: yup.mixed().notRequired("Json file  is required"),
    fileEncoding: yup.string().required("Json file  is required"),
    incantationalLevel: yup
      .string()
      .required("Incantational level  is required"),
    bracketStyle: yup.string().required("Bracket Style is required"),
  });
  let parseJSON;

  const [indentationLevel, setIndentationLevel] = useState(3);
  const [bracketStylesView, setBracketStylesView] = useState(false);
  const [showGenerativeAiButton, setShowGenerativeAiButton] = useState(false);

  const {
    register,
    reset,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      inputJsonData: "",
      inputJsonFile: "",
      fileEncoding: "UTF-8",
      incantationalLevel: "THREE_SPACES",
      bracketStyle: "COLLAPSE",
    },
    resolver: yupResolver(FormValidationSchema),
  });

  const copyJson = () => {
    navigator?.clipboard
      ?.writeText(JSON.stringify(formattedJsonValue, null, indentationLevel))
      .then(
        () => {
          if (formattedJsonValue != "") {
            toast.success("JSON copied to clipboard");
          }
        },
        () => {
          if (formattedJsonValue != "") {
            toast.warning("Failed to copy JSON to clipboard");
          }
        }
      );
  };

  const downloadJson = () => {
    const jsonBlob = new Blob([JSON.stringify(formattedJsonValue)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(jsonBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "FormattedJSON.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    if (url) {
      toast.success("File downloaded successfully!");
    }
  };
  const { REACT_APP_BACKEND_URL } = process.env;

  const onSubmit = async (data) => {
    setFormattedJsonValue("");

    try {
      // if (data?.inputJsonData && data?.inputJsonFile) {
      //   // toast.warning("You need to upload file or use copy Paste only");
      // }

      if (data?.inputJsonFile) {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        let formData = new FormData();

        formData.append("inputJsonFile", data.inputJsonFile);
        formData.append("fileEncoding", data.fileEncoding);
        formData.append("incantationalLevel", data.incantationalLevel);
        formData.append("bracketStyle", data.bracketStyle);

        try {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/formatter/json-format`,
              formData,
              config
            )
            .then((res) => {
              console.log("response", res);

              if (showFormatNewWindow) {
                setShowEditor(false);
              } else {
                setShowEditor(true);
              }
              //  setFormattedJsonValue(res.data.formattedJson);
              setError(false);
              setSuccess(true);

              setSuccessMessage("Formatting Done Successfully!");
              //  setFormattedJsonValue(JSON.parse(res?.data?.formattedJson));
              if (indentationLevel == 10 || indentationLevel == 9) {
                setFormattedJsonValue(res?.data?.formattedJson);
              } else {
                setFormattedJsonValue(JSON.parse(res?.data?.formattedJson));
              }

              // toast.success("Formatting done successfully!");
              setError(false);
              document.getElementById("editor_main_id")?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            })
            .catch((error) => {
              console.error("API request failed:", error);
              if (error.response && error.response.data.errorMessage) {
                // Display the error message from the API response
                setError(true);
                setShowGenerativeAiButton(true);
                setSuccess(false);
                setErrorMessage(error.response.data.errorMessage);
                document.getElementById("editor_main_id")?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              } else {
                // Display a generic error message
              }
            });
        } catch (error) {
          console.log("error1", error);
          toast.warning("Something went wrong!");
          document.getElementById("editor_main_id")?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      } else {
        try {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/formatter/json-format`,
              data
            )
            .then((res) => {
              console.log("response", res);

              if (showFormatNewWindow) {
                setShowEditor(false);
              } else {
                setShowEditor(true);
              }

              // setFormattedJsonValue(res.data.formattedJson);
              if (indentationLevel == 10 || indentationLevel == 9) {
                setFormattedJsonValue(res?.data?.formattedJson);
              } else {
                setFormattedJsonValue(JSON.parse(res?.data?.formattedJson));
              }

              setError(false);
              setSuccess(true);
              // reset();
              setSuccessMessage("Formatting Done Successfully!");
              // toast.success("Formatting done successfully!");
              setError(false);
              document.getElementById("editor_main_id")?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
              // }
            })
            .catch((error) => {
              console.error("API request failed:", error);
              if (error.response && error.response.data.errorMessage) {
                // Display the error message from the API response
                setError(true);
                setShowGenerativeAiButton(true);
                setSuccess(false);
                setErrorMessage(error.response.data.errorMessage);
                document.getElementById("editor_main_id")?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              } else {
                // Display a generic error message
              }
            });
        } catch (error) {
          console.log("error2", error);
          toast.warning("Something went wrong!");
          document.getElementById("editor_main_id")?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
    } catch (error) {
      console.log("error", error);
      document.getElementById("editor_main_id")?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    setValue("fileEncoding", "UTF-8");
    setValue("incantationalLevel", "THREE_SPACES");

    setValue("bracketStyle", "COLLAPSE");
    setBracketStylesView(false);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleButtonClick = () => {
    const newTab = window.open("", "_blank");

    const htmlContent = `
        <!DOCTYPE html>
        <html>
        <head>
            <title>JSON Formatted Page</title>
        </head>
        <body>
            <pre>${formattedJsonValue}</pre>
        </body>
        </html>
    `;

    newTab.document.write(htmlContent);
  };

  console.log("errors", errors);
  const CustomInputContent = () => {
    const fileInputRef = useRef(null);

    const [file, setFile] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    let fileName;
    const handleBrowseClick = () => {
      fileInputRef.current.click(); // Trigger click event on file input
    };

  
      const handleFileChange = (e) => {
        // Handle file change event here
        setIsLoading(true);
        const selectedFile = e.target.files[0];
        console.log("Selected file", selectedFile.name);
        setFile(selectedFile.name);
        setValue("inputJsonFile", selectedFile);
        setInputJsonValueFile(selectedFile);
           setIsLoading(false);
      };

    return (
      <div className="main-container">
        <div className="custom-dnd">
          <img src={UploadIcon} className="uploadIcon" alt="upload-icon" />

          <h6 className="custom-input-text">Choose a file</h6>
        </div>
        <div>
          {!isLoading?(
            <button
              type="button"
              onClick={handleBrowseClick}
              className="browse-file-button"
            >
              Browse File
            </button>
          ):(<div>Loading</div>)}
          <input
            accept=".json"
            type="file"
            multiple={false}
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
        {!isLoading && <h6 className="custom-input-text">{file}</h6>}
      </div>
    );
  };
  const fileEncodingOptions = [
    {
      label: "UTF-8",
      value: "UTF-8",
    },
    {
      label: "UTF-16",
      value: "UTF-16",
    },
  ];
  const bracketStyleOptions = [
    {
      label: "Collapsed (braces on same line)",
      value: "COLLAPSE",
    },
    {
      label: "Expanded (braces on different line)",
      value: "EXPAND",
    },
  ];
  const indentationLevelOptions = [
    {
      value: "TWO_SPACES",
      label: "2 spaces per indent level",
    },
    {
      value: "THREE_SPACES",
      label: "3 spaces per indent level",
    },
    {
      value: "FOUR_SPACES",
      label: "4 spaces per indent level",
    },
    {
      value: "TABS",
      label: "Tab delimited",
    },
    {
      value: "COMPACT",
      label: "Compact (1 line)",
    },
    {
      value: "JAVASCRIPT",
      label: "JavaScript escaped",
    },
  ];

  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    option: (styles, { data, isDisabled, isFocused = false, isSelected }) => {
      // const color = chroma(data.color);
      console.log("isFocused", isFocused);
      return {
        ...styles,

        // backgroundColor: isDisabled
        //   ? undefined
        //   : isFocused
        //   ? "#BF66B6"
        //   : isSelected
        //   ? "#c00eae"
        //   : undefined,

        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#BF66B6"
          : isFocused
          ? "#c00eae"
          : undefined,

        color: isDisabled
          ? "#ccc"
          : isFocused || isSelected
          ? "white"
          : data.color,
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#c00eae"
              : "#BF66B6"
            : undefined,
        },
      };
    },
  };

  const customStyles = {
    option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
      ...provided,
      fontSize: "14px",
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#BF66B6"
        : isFocused
        ? "#c00eae"
        : undefined,
      color: isDisabled
        ? "#ccc"
        : isFocused || isSelected
        ? "white"
        : data.color,
      ":active": {
        ...provided[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#c00eae"
            : "#BF66B6"
          : undefined,
      },
    }),
  };
  const [fileEncodingValue, setFileEncodingValue] = useState();
  const [indentationLevelValue, setIndentationLevelValue] = useState(
    indentationLevelOptions[1]
  );

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [bracketStyleValue, setBracketStyleValue] = useState(
    bracketStyleOptions[1]
  );
  const [inputJsonValue, setInputJsonValue] = useState();
  const [inputJsonValueFile, setInputJsonValueFile] = useState();
  const [formattedJsonValue, setFormattedJsonValue] = useState("");
  const [showEditor, setShowEditor] = useState(false);
  const [showFormatNewWindow, setShowFormatNewWindow] = useState(false);

  const [isFileDropped, setIsFileDropped] = useState(false);

  const handleChangeStatus = (fileStatus) => {
    if (fileStatus === "done") {
      setIsFileDropped(true);
    } else {
      setIsFileDropped(false);
    }
  };
  const getUploadParams = ({ file }) => {
    const body = new FormData();
    setValue("inputJsonFile", file);
    setInputJsonValueFile(file);

    body.append("dataFiles", file);
    return { url: "http://localhost:3000/uploadmultifile", body };
  };

  // preview component
  const Preview = ({ meta }) => {
    const { name, size, percent, status, previewUrl } = meta;
    console.log("dropZOneFile.meta", meta);
    return (
      <div className="preview-box">
        <span className="name">{name}</span> -{" "}
        <span className="status">{size}KB</span>
      </div>
    );
  };

  const options = {
    selectOnLineNumbers: true,
    readOnlyCodeFolding: true,

    bracketPairColorization: 100,
    blockCommentFolding: true,
    fontSize: 16,
    minimap: {
      enabled: false,
    },
    contextmenu: false,
    createFoldingRangeFromSelection: true,
  };

  const stylesEditor = {
    dualView: {
      display: "flex",
    },
    jsonViewer: {
      borderLeft: "1px dashed white",
      lineHeight: 1.25,
      width: "50%",
      borderLeft: "1px solid lightgrey",
      margin: 10,
    },
    jsonEditor: {
      width: "50%",
      fontSize: 12,
      fontFamily: "Lucida Console, monospace",
      lineHeight: 1.25,
    },
    root: {
      fontSize: 12,
      fontFamily: "Lucida Console, monospace",
      lineHeight: 1.25,
      /*color: "#3E3D32"*/
    },
    label: {
      color: "DeepPink",
      marginTop: 3,
    },
    value: {
      marginLeft: 10,
    },
    row: {
      display: "flex",
    },
    withChildrenLabel: {
      color: "DeepPink",
    },
    select: {
      borderRadius: 3,
      borderColor: "grey",
      backgroundColor: "DimGray",
      color: "khaki",
    },
    input: {
      borderRadius: 3,
      border: "1px solid #272822",
      padding: 2,
      fontFamily: "Lucida Console, monospace",
      fontSize: 12,
      backgroundColor: "gray",
      color: "khaki",
      width: "200%",
    },
    addButton: {
      cursor: "pointer",
      color: "LightGreen",
      marginLeft: 15,
      fontSize: 12,
    },
    removeButton: {
      cursor: "pointer",
      color: "magenta",
      marginLeft: 15,
      fontSize: 12,
    },
    saveButton: {
      cursor: "pointer",
      color: "green",
      marginLeft: 15,
      fontSize: 12,
    },
    builtin: {
      color: "green",
      fontSize: 12,
    },
    text: {
      color: "black",
      fontSize: 12,
    },
    number: {
      color: "purple",
      fontSize: 12,
    },
    property: {
      color: "DeepPink",
      fontSize: 12,
    },
    collapseIcon: {
      cursor: "pointer",
      fontSize: 10,
      color: "teal",
    },
  };

  const fetchSolution = async () => {
    let inputJson = "";
    try {
      if (inputJsonValueFile) {
        setLoading(true);
        console.log("inputJsonValueFile", inputJsonValueFile);
        const file = inputJsonValueFile;
        const reader = new FileReader();
        reader.onload = async (e) => {
          inputJson = e.target.result;
          console.log("inputJson", inputJson);
          const solution = await getSolution(`Correct this JSON ${inputJson}`);
          setFormattedJsonValue(JSON?.parse(solution));
          setLoading(false);
          setError(false);
        };
        reader.readAsText(file);
      } else {
        setLoading(true);

        // If no file is provided, use a default question or handle the case accordingly
        inputJson = inputJsonValue;
    
        // const solution = await getSolution(
        //   `Correct this JSON and return only valid JSON ${inputJson}`
        // );
        const solution = await getSolution(
          `Correct this JSON and return only the valid JSON object. Do not include any explanations, text, or formatting like ${inputJson}`
        );

 
        setFormattedJsonValue(JSON?.parse(solution));
        setLoading(false);
        setError(false);
      }

      // const ans = await getSolution("Write a code on sum of 2 numbers in js");
      // console.log("langchain.ans", ans);
    } catch (error) {
      console.error("Error fetching solution:", error);
      document.getElementById("editor_main_id")?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  // if(loading){
  //   return (
  //     <div className="loader-container"><div class="spinner-grow text-primary" role="status">

  //     </div><span class="sr-only">Loading...</span></div>
  //   );
  // }

  return (
    <div className="container-right mt-5 mt-md-0">
      <Helmet>
        <meta charset="UTF-8" />

        <title>Free JSON Formatter & Online Editor | SDE Tools</title>
        <meta
          name="description"
          content="Easily format and edit your JSON data online with free JSON Formatter of SDE Tools. Copy-paste your code or upload JSON data and choose indentation level"
        />
        <meta
          name="keywords"
          content="JSON Formatter, JSON Editor, Beautify JSON"
        />

        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://sdetools.io/formatter/json-formatter"
        />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="SDE Tools" />
        <meta
          property="og:title"
          content="Free JSON Formatter & Online Editor | SDE Tools"
        />
        <meta
          property="og:description"
          content="Easily format and edit your JSON data online with free JSON Formatter of SDE Tools. Copy-paste your code or upload JSON data and choose indentation level"
        />
        <meta
          property="og:url"
          content="https://sdetools.io/formatter/json-formatter"
        />
      </Helmet>

      {error && (
        <>
          <div className="error-message">
            <div className="row">
              <div className="col-8">{errorMessage}</div>
              <div className="col-4">
                <div className="row">
                  <div className="col-10 gen-ai-button">
                    {showGenerativeAiButton && (
                      <button
                        className="btn-hover color-11"
                        onClick={fetchSolution}
                        type="button"
                      >
                        <BsStars
                          className="mr-2"
                          style={{
                            marginRight: "8px",
                            transform: "rotate(-90deg)",
                            transition: "transform 0.3s ease-in-out", // Optional: adds smooth rotation effect
                          }}
                          size={25}
                        />
                        Smart Fix
                      </button>
                    )}
                  </div>
                  <div className="col-2">
                    {" "}
                    <button className="closeButton">
                      <MdOutlineClose onClick={() => setError(false)} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="error-message d-none">
            <button className="closeButton">
              <MdOutlineClose onClick={() => setError(false)} />
            </button>
            {errorMessage}
          </div>
          {showGenerativeAiButton && (
            <div className="format-button-generative-ai  d-none">
              {/* <button
                className="format-btn format-button-generative-ai"
                onClick={fetchSolution}
                type="button"
              >
               <BsStars /> Format JSON Using Generative Ai
              </button>*/}
              <button
                className="btn-hover color-11"
                onClick={fetchSolution}
                type="button"
              >
                <BsStars size={25} />
                Smart Fix
              </button>
            </div>
          )}
        </>
      )}
      <br />

      {success && (
        <div className="success-message ">
          <button className="closeButton">
            <MdOutlineClose onClick={() => setSuccess(false)} />
          </button>
          {successMessage}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="card-formatter mt-5 mt-lg-0  border border-0 card rounded-md bg-white shadow shadow-sm">
          <div className="row">
            <div className="col-12 col-md-5 mobile-responsive-col left-container-padding">
              <div className="copy-paste">
                <label className="">Copy-paste your JSON here</label>
                <textarea
                  onChange={(e) => {
                    setInputJsonValue(e.target.value);
                    setValue("inputJsonData", e.target.value);
                  }}
                  placeholder="Copy-paste your JSON here"
                  className="text-area-formatter-new-formatter"
                />
              </div>

              <div className="button-section-2 row">
                <div className="col-12 d-none col-md-6 mobile-responsive-view-btn-container">
                  <button
                    onClick={() => {
                      if (formattedJsonValue !== "") {
                        handleButtonClick();
                        setShowFormatNewWindow(true);
                      }
                    }}
                    type="submit"
                    className="col-span-1 format-btn button-mob  py-2 text-center"
                  >
                    Format JSON to new window
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-2 column-2-center mobile-responsive-col left-container-padding">
              <div className="copy-paste ">
                {/*<label>Or upload your JSON file</label>*/}
                <label>Upload JSON file</label>
              </div>
              <div>
                <Dropzone
                  classNames="dropzone-container"
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  styles={{
                    dropzone: {
                      overflow: "auto",
                      border: "1px dashed  #C6C3C3",
                      background: "#f5f5f5",
                      width: "100%",
                      height: "145px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                    inputLabelWithFiles: { margin: "20px 3%" },
                  }}
                  canRemove={true}
                  maxFiles={1}
                  PreviewComponent={Preview}
                  accept=".json"
                  inputContent={<CustomInputContent />}
                />
              </div>
              <div className="col-span-1  col-12 col-md-12  py-2 text-center">
                <button
                  className="format-btn"
                  onClick={() => setShowFormatNewWindow(false)}
                  type="submit"
                >
                  Format JSON
                </button>
              </div>
            </div>
            <div className="col-12 col-md-5 mobile-responsive-col">
              <div className="row mobile-res-row" id="editor_main_id">
                <div className="col-8">
                  <h6 className="formatted-heading">Formatted JSON</h6>
                </div>

                <div className="editor_holder_button col-4">
                  <button
                    onClick={copyJson}
                    type="button"
                    className="editor_holder_button_bg"
                  >
                    <BiCopyAlt />
                  </button>
                  <button
                    onClick={() => {
                      if (formattedJsonValue != "") {
                        downloadJson();
                      }
                    }}
                    type="button"
                    className="editor_holder_button_bg ms-3"
                  >
                    <RiFileDownloadFill />
                  </button>
                </div>
              </div>

              {/* <JSONViewer
                data={formattedJsonValue}
                collapsible
                styles={stylesEditor}
            />*/}
              {/* <JSONInput
                id="a_unique_id"
                placeholder={formattedJsonValue|| {}}
                locale={locale}
                width="700px"
                height="700px"
                theme={"light_mitsuketa_tribute"}
          />*/}
              <div
                className={`editor-border-new-fomatter ${
                  formattedJsonValue ? "editor-scroll-enable" : ""
                }`}
              >
                {/*   <JsonPreview
                  jsonData={formattedJsonValue}
                  indentationLevel={indentationLevel}
                  collapsed={true}
                />*/}
                {formattedJsonValue && (
                  <div className="    react-json-editor ">
                    {indentationLevel != 10 && indentationLevel != 9 ? (
                      <ReactJson
                        displayArrayKey={false}
                        enableClipboard={false}
                        displayObjectSize={false}
                        displayDataTypes={false}
                        indentWidth={indentationLevel || 3}
                        collapsed={bracketStylesView}
                        src={formattedJsonValue}
                        name={false}
                      />
                    ) : (
                      <Editor
                        value={formattedJsonValue}
                        onValueChange={(code) => setFormattedJsonValue(code)}
                        highlight={(code) => highlight(code, languages.js)}
                        padding={10}
                        readOnly
                        style={{
                          fontFamily: '"Fira code", "Fira Mono", monospace',
                          fontSize: 12,
                          // border: "2px solid ",
                          borderColor: "#e4e4e4",
                          borderRadius: "10px",
                          minHeight: "80%",
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
              {/* <Editor
                value={formattedJsonValue}
                onValueChange={(code) => setFormattedJsonValue(code)}
                highlight={(code) => highlight(code, languages.js)}
                padding={10}
                readOnly
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                  border: "2px solid ",
                  borderColor: "#e4e4e4",
                  borderRadius: "10px",
                  minHeight: "80%",
                }}
              />*/}
            </div>

            <div className="button-section row">
              <div className="col-span-1 copy-paste col-12 col-md-4 bg-gray-200 py-2 text-start">
                <label className="label-drop-down ">File Encoding</label>
                <Select
                  options={fileEncodingOptions}
                  isClearable={false}
                  styles={styles}
                  defaultValue={fileEncodingOptions[0]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setFileEncodingValue(e);
                    // setCourseName(e);
                    // const selectedValues = e.map((option: any) => option.value);
                    // console.log("selectedValues", selectedValues);
                    setValue("fileEncoding", e.value);
                  }}
                  classNamePrefix="select"
                  value={fileEncodingValue}
                />
              </div>

              <div className="col-span-1 copy-paste col-12 col-md-4 bg-gray-200 py-2 text-start">
                <label className="label-drop-down">Indentation level</label>
                <Select
                  options={indentationLevelOptions}
                  isClearable={false}
                  styles={customStyles}
                  defaultValue={indentationLevelOptions[1]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setIndentationLevelValue(e);

                    setFormattedJsonValue("");
                    setSuccess(false);
                    setValue("incantationalLevel", e.value);

                    switch (e.value) {
                      case "TWO_SPACES":
                        setIndentationLevel(2);
                        break;
                      case "THREE_SPACES":
                        setIndentationLevel(3);
                        break;
                      case "FOUR_SPACES":
                        setIndentationLevel(4);
                        break;
                      case "TABS":
                        setIndentationLevel(8);
                        break;
                      case "COMPACT":
                        setIndentationLevel(9);
                        break;
                      case "JAVASCRIPT":
                        setIndentationLevel(10);
                        break;
                      default:
                        setIndentationLevel(3);
                        break;
                    }
                  }}
                  classNamePrefix="select"
                  value={indentationLevelValue}
                />
              </div>
              <div className="col-span-1 copy-paste col-12 col-md-4 bg-gray-200 py-2 text-start">
                <label className="label-drop-down">Bracket style</label>
                <Select
                  options={bracketStyleOptions}
                  isClearable={false}
                  styles={styles}
                  // defaultInputValue={bracketStyleOptions[0]}
                  defaultValue={bracketStyleOptions[1]}
                  onChange={(e) => {
                    setBracketStyleValue(e);
                    setValue("bracketStyle", e.value);
                    switch (e.value) {
                      case "COLLAPSE":
                        setBracketStylesView(true);
                        break;
                      case "EXPAND":
                        setBracketStylesView(false);
                        break;

                      default:
                        setBracketStylesView(false);
                        break;
                    }
                  }}
                  classNamePrefix="select"
                  value={bracketStyleValue}
                />
              </div>
            </div>
          </div>
        </Card>
      </form>

      <div className="main-content-container">
        <div className="heading-formatter">
          <h1 className="heading-formatter-text">
            Free JSON Formatter & Online Editor
          </h1>
          <p className="heading-paragraph">
            Ensure the clarity and integrity of your JSON data with our Free
            Online JSON Formatter. This free tool empowers developers of all
            backgrounds to effortlessly transform raw JSON code into a
            well-structured and readily comprehensible format with selected
            level of indentation. Objects, arrays and members can easily be
            spotted and if required, the generated JSON tree can be explored by
            compressing each node at a time. Assists in two bracket styles with
            braces on the same line as well different lines. Uses UTF-8 and
            UTF-16 for encoding the text and offers a total of six levels of
            indentation: 2 spaces, 3 spaces, 4 spaces, tab delimited, compact (1
            line) and JavaScript escaped.
          </p>
        </div>
        <br />
        <div className="sub-heading">
          <h2 className="main-heading">Benefits of Our JSON Formatter </h2>
          <ul className="sub-list-ul">
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
                className="bold"
              >
                Effortless Formatting:
              </h3>
              Simply provide your JSON code within the designated input field,
              and our tool will instantly generate a visually appealing output.
              Indentation is automatically optimized for a clear hierarchical
              representation of your data.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Error Detection and Validation:
              </h3>
              Our JSON Formatter is incorporated with rigorous error checking
              functionalities. It analyses your code to identify any syntax
              errors or invalid JSON structures, allowing for prompt
              rectification and saving valuable debugging time.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Enhanced Code Readability:
              </h3>
              A well-formatted JSON data not only simplifies readability for
              yourself but also significantly benefits collaborators on
              projects. Explicit indentation and structure bolster code
              maintainability and prevent confusion in the future.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Online Accessibility and Free Use:
              </h3>
              Our JSON Formatter is readily available online, accessible from
              any device with internet access. There's no need for downloads or
              installations; simply copy and paste your code, and receive your
              formatted JSON in seconds!
            </li>
          </ul>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">What Is JSON?</h6>
          <p className="main-heading-paragraph">
            Java Script Object Notation (JSON) is an open lightweight data
            format used for storing and transferring information through
            human-readable text. It is an independent language format for the
            interchange. It depends on JavaScript object literal syntax, however
            is not language-specific. This makes it easier for different
            programming languages to analyze and process the data.
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">Why Use JSON?</h6>
          <p className="main-heading-paragraph">
            JSON serves as a great tool for exchanging the data. There are
            several reasons that justifies its use:
          </p>
          <ul className="sub-list-ul">
            <li>
              <strong>Simplicity:</strong> Being a lightweight data formatter,
              it is very straightforward for transmitting the data easily.
            </li>
            <li>
              <strong>Readability:</strong> Simple structure and plain text
              makes it very easy for humans to read.
            </li>
            <li>
              <strong>Variety:</strong> Tons of JSON libraries are available for
              programming as well as it includes different arrays, strings, data
              structure and objects.
            </li>
          </ul>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">
            Why use SDE Tools Validator and Formatter?
          </h6>
          <p className="main-heading-paragraph">
            It automatically formats your code in accordance with the coding
            standards. SDE Tools offers you various advantages:
          </p>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                It helps in increasing the productivity as it provides you
                features like code completion and highlighting the syntax
                minimizing errors.
              </li>
              <li>
                To fit your coding style, the editor lets you adjust formatting
                choices and handles several file encodings.
              </li>

              <li>
                It enhances the readability by providing clarity to all the
                developers.
              </li>
            </ul>
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">How does a SDE Tools Editor work?</h6>
          <p className="main-heading-paragraph">
            The validator functions similarly to a computerized code reviewer.
            It goes line by line through your code. It checks:
          </p>
          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                Mistakes such as mismatched braces, improper function calls, and
                misplaced semicolons.
              </li>
              <li>
                The coding style differentiation such as indentation spaces.
              </li>

              <li>
                A report is provided by the validator which includes identified
                problems and enables you to fix those problems.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default JsonFormatters;
