/* eslint-disable react-hooks/exhaustive-deps */
import SidebarItem from "./SidebarItem"
 
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import Logo from "../../assets/Logo/logo.png";
import SmallLogo from "../../assets/Logo/small-logo.png";
 
import { RxHamburgerMenu } from "react-icons/rx";
import { Link } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { useEffect, useState } from "react";

export default function Sidebar({isSidebarOpen,toggleSidebar}){
  
 
    // const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    // const toggleSidebar = () => {
    //   setIsSidebarOpen(!isSidebarOpen);
    // };

    const [activeSubMenu, setActiveSubMenu] = useState(null);
     const toggleSubMenu = (index) => {
      console.log("index", index);
       setActiveSubMenu(index === activeSubMenu ? null : index);
     };
// useEffect(()=>{

//   toggleSidebar();
// },[])

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1024px)");

    if (mediaQuery.matches) {
      toggleSidebar();
    }

    // Cleanup function if necessary
    return () => {
      // any cleanup code
    };
  }, []);
    return (
      <div>
        {isSidebarOpen ? (
          <>
            <div
              className={`sidebar-container ${
                isSidebarOpen ? "open" : "closed"
              }`}
            >
              <div className="sidebar-container-inside row">
                <Link to={"/"} className="col-10 logo-img">
                  <img
                    className="sidebar-logo-container "
                    src={Logo}
                    alt="logo"
                  />
                </Link>
                <div className="col-2">
                  <RxHamburgerMenu
                    onClick={toggleSidebar}
                    className="hamburger-sidebar "
                  />
                </div>
              </div>

              <div className="sidebar-heading-main d-none">
                <h4>MAIN MENU</h4>
              </div>
              <div className="sidebar-content-container">
                {SidebarData.map((data, index) => (
                  <SubMenu
                    subnav={activeSubMenu === index}
                    toggleSubMenu={() => toggleSubMenu(index)}
                    isSidebarOpen={isSidebarOpen}
                    key={index}
                    data={data}
                  />
                ))}
              </div>
            </div>

            <div
              className={`sidebar-container-mobile ${
                isSidebarOpen ? "open" : "closed"
              }`}
            >
              <div className="row  mobile-header-container">
                <Link to={"/"} className="col-10 logo-img-mobile">
                  <img className="logo-img-mobile " src={Logo} alt="logo" />
                </Link>
                <div className="col-2">
                  {isSidebarOpen ? (
                    <RxHamburgerMenu
                      onClick={toggleSidebar}
                      className="hamburger-sidebar "
                    />
                  ) : (
                    <MdOutlineClose
                      onClick={toggleSidebar}
                      className="hamburger-sidebar "
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="sidebar-collapsed">
              <div className="collapse-side-bar-sub-menu">
                <Link to={"/"} className="col-10 logo-img">
                  <img
                    className="sidebar-logo-container-collapse "
                    src={SmallLogo}
                    alt="logo"
                  />
                </Link>

                <RxHamburgerMenu
                  onClick={toggleSidebar}
                  className="ham-burger-side-bar-collapse "
                />
              </div>
              <div className="sidebar-content-container">
                {SidebarData.map((data, index) => (
                  <SubMenu
                    subnav={activeSubMenu === index}
                    toggleSubMenu={() => toggleSubMenu(index)}
                    toggleSidebar={toggleSidebar}
                    isSidebarOpen={isSidebarOpen}
                    key={index}
                    data={data}
                  />
                ))}
              </div>
            </div>
            <div
              className={`sidebar-container-mobile ${
                isSidebarOpen ? "open" : "closed"
              }`}
            >
              <div className="row  mobile-header-container">
                <Link to={"/"} className="col-10 logo-img-mobile">
                  <img className="logo-img-mobile " src={Logo} alt="logo" />
                </Link>
                <div className="col-2">
                  {isSidebarOpen ? (
                    <RxHamburgerMenu
                      onClick={toggleSidebar}
                      className="hamburger-sidebar "
                    />
                  ) : (
                    <MdOutlineClose
                      onClick={toggleSidebar}
                      className="hamburger-sidebar "
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className={`sidebar-container-mobile_responsive ${
                isSidebarOpen ? "open" : "closed"
              }`}
            >
              <div className="sidebar-content-container">
                {SidebarData.map((data, index) => (
                  <SubMenu
                    subnav={activeSubMenu === index}
                    toggleSubMenu={() => toggleSubMenu(index)}
                    toggleSidebar={toggleSidebar}
                    isSidebarOpen={!isSidebarOpen}
                    key={index}
                    data={data}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    );
}
