import React, { useEffect, useState } from 'react'
 
import TableList from './table';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
 
import { Link } from 'react-router-dom';
import { IoAddOutline } from "react-icons/io5";
import "./blog.css"
import { setData } from '../../redux/features/Blog/blogSlice';
 
const Blog = () => {
  const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
     const blogData = useSelector((state) => state.blog.data);
    const dispatch=useDispatch()
  useEffect(() => {
    // Define the async function to fetch data
    const fetchData = async () => {
      try {
        // Use REACT_APP_BACKEND_URL from environment variables
        const url = `${process.env.REACT_APP_BACKEND_URL}/blog/get-all-blogs`;
        const response = await axios.get(url).then((res)=>{
          console.log("response", res);
          dispatch(setData(res.data.data));
        })
     
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };


    fetchData();
  }, []); 
  return (
    <div>
      <div className="container">
        <div className="table-btn">
          <h2 className="title-admin">Blogs</h2>
          <Link to={"/admin/blogs/add-blog"}>
            <button className="admin-button">
              <IoAddOutline size={25} /> Add
            </button>
          </Link>
        </div>
        <div>
          <TableList data={blogData} />
        </div>
      </div>
    </div>
  );
}

export default Blog