import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../../redux/features/category/categorySlice";
import "./view.css";
import Select from "react-select";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FileInput } from "rizzui";
import { setEditBlogData } from "../../../redux/features/Blog/blogSlice";
const ViewBlog = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const blogEditData = useSelector((state) => state.blog.editData);

  const { id } = useParams();

  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(
    `${process.env.REACT_APP_BACKEND_URL}/${blogEditData?.blog_Image}`
  );

  const handleBack = () => {
    navigate(-1);
  };
  // const styles = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     fontSize: "14px",
  //   }),
  // };
  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    option: (styles, { data, isDisabled, isFocused = false, isSelected }) => {
      // const color = chroma(data.color);
      console.log("isFocused", isFocused);
      return {
        ...styles,

        // backgroundColor: isDisabled
        //   ? undefined
        //   : isFocused
        //   ? "#BF66B6"
        //   : isSelected
        //   ? "#c00eae"
        //   : undefined,

        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#BF66B6"
          : isFocused
          ? "#c00eae"
          : undefined,

        color: isDisabled
          ? "#ccc"
          : isFocused || isSelected
          ? "white"
          : data.color,
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#c00eae"
              : "#BF66B6"
            : undefined,
        },
      };
    },
  };

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [categoryValue, setCategoryValue] = useState(blogEditData?.category_id);
  const [tagValue, setTagValue] = useState(null);
  const [value, setValue] = useState(blogEditData?.description);
  const validationSchema = Yup.object({
    title: Yup.string()
      .required("Title is required")
      .min(2, "Title must be at least 2 characters")
      .max(100, "Title must be less than 100 characters"),

    description: Yup.string()
      .required("Description is required")
      .min(10, "Description must be at least 10 characters")
      .max(5000, "Description must be less than 5000 characters"),

    reading_time: Yup.string().required("Reading Time is required"),
    // .matches(/^\d+$/, "Reading Time must be a number"), // Assuming reading_time is a number in string format

    category_id: Yup.string()
      .required("Category ID is required")
      .matches(
        /^[a-fA-F0-9]{24}$/,
        "Category ID must be a valid MongoDB ObjectId"
      ), // Adjust pattern as needed for your use case

    blog_Image: Yup.mixed().notRequired(),
    // .test("fileSize", "File Size is too large", (value) => {
    //   // Optional: Validate file size if needed
    //   return value && value.size <= 5 * 1024 * 1024; // Example: 5MB size limit
    // })
    // .test("fileType", "Unsupported File Format", (value) => {
    //   // Optional: Validate file type if needed
    //   return value && ["image/jpeg", "image/png"].includes(value.type);
    // }),

    tags: Yup.array()
      .of(Yup.string().required("Each tag is required")) // Validate each tag as a string
      .min(1, "At least one tag is required") // Ensure at least one tag is provided
      .required("Tags are required"),
  });

  const initialValues = {
    title: blogEditData?.title || "",
    description: blogEditData?.description || "",
    reading_time: blogEditData?.reading_time || "",
    category_id: blogEditData?.category_id || "",
    blog_Image: null,
    tags: blogEditData?.tags?.map((tag) => tag._id) || [],
  };
  const fetchData = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/blog/category/get-category`;
      const response = await axios.get(url);
      console.log("response", response);
      const formattedOptions = response.data.data.map((category) => ({
        label: category.name, // Display name
        value: category._id, // Value to use in form submission
      }));

      const defaultMappedCategory = formattedOptions.find(
        (data) => data.value == blogEditData?.category_id
      );
      setCategoryValue(defaultMappedCategory);

      setCategoryOptions(formattedOptions);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const fetchDataTags = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/blog/tags`;
      const response = await axios.get(url);
      console.log("response", response);
      const formattedOptions = response.data.data.map((category) => ({
        label: category.name,
        value: category._id,
      }));
      setTagOptions(formattedOptions);

      const selectedTags = formattedOptions.filter((option) =>
        blogEditData?.tags?.some((tag) => tag._id === option.value)
      );

      const selectedValues = selectedTags.map((option) => option.value);
      setTagValue(selectedTags);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDataTags();
  }, []);
  useEffect(() => {
    // Define the async function to fetch data
    const fetchDataEdit = async () => {
      try {
        // Use REACT_APP_BACKEND_URL from environment variables
        const url = `${process.env.REACT_APP_BACKEND_URL}/blog/get-blog/${id}`;
        const response = await axios.get(url).then((res) => {
          //  setFieldValue("name", response.data.data.name || "");
          dispatch(setEditBlogData(res.data.data));
        });
      } catch (err) {
      } finally {
      }
    };

    fetchDataEdit();
  }, []);
  // Handle form submission
  const onSubmit = async (values, { resetForm }) => {
    try {
      // Create a FormData object
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("reading_time", values.reading_time);
      formData.append("category_id", values.category_id);

      // Append each tag individually
      values.tags.forEach((tag, index) => {
        formData.append(`tags[${index}]`, tag);
      });

      // Append the image file
      if (values.blog_Image) {
        formData.append("blog_Image", values.blog_Image);
      }

      // Send the POST request with the FormData object
      await axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/blog/update-blog/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(async (res) => {
          toast.success("Blog Added Successfully!");

          // Fetch updated data
          await fetchData();

          // Navigate to a different route
          navigate("/admin/blogs");

          // Reset the form
          resetForm();
        });
    } catch (error) {
      console.error("Error adding blog:", error);
      let errorMessage = "Something Went Wrong!";

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }

      toast.error(errorMessage);
    }
  };

  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    if (file) {
      setSelectedFile(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setPreviewUrl(null);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <div className="container">
      <Card className="card-formatter border border-0 card rounded-md bg-white shadow shadow-sm p-4">
        <h3 className="mb-4 title-admin">View Blog</h3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <div className="grid-container">
                <div className="grid-item">
                  <label>
                    Select Category <span style={{ color: "red" }}>*</span>
                  </label>
                  <Select
                    options={categoryOptions}
                    isClearable={false}
                    styles={styles}
                    isDisabled={true}
                    onChange={(e) => {
                      console.log("onchange", e);
                      setCategoryValue(e);

                      setFieldValue("category_id", e.value);
                    }}
                    classNamePrefix="select"
                    value={categoryValue}
                  />
                  <ErrorMessage
                    name="category_id"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>
                <div className="grid-item">
                  <div className="form-group">
                    <label htmlFor="title ">
                      Blog Title<span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      disabled
                      id="title"
                      name="name"
                      className="form-control"
                      placeholder="Enter blog title"
                      value={values.title}
                      onChange={(e) => {
                        setFieldValue("title", e?.target?.value);
                      }}
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-danger mt-1"
                    />
                  </div>
                </div>
                <div className="grid-item">
                  <label>Select Tags</label>
                  <Select
                    options={tagOptions}
                    isClearable={false}
                    closeMenuOnSelect={false}
                    isMulti
                    isDisabled={true}
                    styles={styles}
                    onChange={(e) => {
                      setTagValue(e);
                      const selectedValues = e.map((option) => option.value);
                      console.log("selectedValues", selectedValues);
                      setFieldValue("tags", selectedValues);
                    }}
                    classNamePrefix="select"
                    value={tagValue}
                  />
                  <ErrorMessage
                    name="tags"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>
              </div>
              <div className="grid-item">
                <label>
                  Description <span style={{ color: "red" }}>*</span>
                </label>
                <div className="text-editor">
                  <ReactQuill
                    theme="snow"
                    value={value}
                    readOnly={true}
                    onChange={(e) => {
                      setValue(e);
                      setFieldValue("description", e);
                    }}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>
              </div>
              <div className="grid-container">
                <div className="grid-item">
                  <div className="form-group">
                    <label htmlFor="reading_time ">
                      Reading Time<span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      id="reading_time"
                      name="name"
                      disabled
                      className="form-control"
                      value={values.reading_time}
                      placeholder="Enter reading time"
                      onChange={(e) => {
                        setFieldValue("reading_time", e?.target?.value);
                      }}
                    />
                    <ErrorMessage
                      name="reading_time"
                      component="div"
                      className="text-danger mt-1"
                    />
                  </div>
                </div>
                <div className="grid-item mt-4 d-none">
                  <div className="form-group">
                    <div className="image-upload-preview">
                      <input
                        type="file"
                        id="imageInput"
                        accept="image/*"
                        onChange={(e) => {
                          console.log("e", e.target.files[0]);
                          setFieldValue("blog_Image", e?.target?.files[0]);
                          handleFileChange(e);
                        }}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                      />
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleButtonClick}
                      >
                        {selectedFile ? selectedFile : "Choose file"}
                      </button>
                    </div>
                    <ErrorMessage
                      name="blog_Image"
                      component="div"
                      className="text-danger mt-1"
                    />
                  </div>
                </div>
                <div className="grid-item mt-4 ">
                  {previewUrl && (
                    <div className="image-preview">
                      <img
                        src={previewUrl}
                        alt="Selected Preview"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100px",
                          borderRadius: "1rem",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <button
                type="button"
                onClick={handleBack}
                className="admin-button  mt-3"
                disabled={isSubmitting}
              >
                Back
              </button>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default ViewBlog;
