import React from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation } from 'react-router-dom';
import "./admin-sidebar.css"

import { MdDashboard } from "react-icons/md";
import { TbCategoryPlus } from "react-icons/tb";
import { TbHexagonLetterB } from "react-icons/tb";
const AdminSideBar = () => {
   const location = useLocation();
  return (
    <div>
      <Sidebar className="sidebar-container" backgroundColor={"#fff"}>
        <Menu
          rootStyles
          menuItemStyles={{
            button: {
              [`&:hover`]: {
                backgroundColor: "#c00eae",
                color: "#fff",
              },
              [`&.active`]: {
                backgroundColor: "#c00eae",
                color: "#fff",
              },
            },
          }}
        >
          <MenuItem
            icon={<MdDashboard />}
            color="white"
            className={
              location.pathname === "/admin/admin-dashboard" ? "ps-active" : ""
            }
            component={<Link to="/admin/admin-dashboard" />}
          >
            {" "}
            Dashboard
          </MenuItem>
          <MenuItem
            className={
              location.pathname === "/admin/category" ? "ps-active" : ""
            }
            icon={<TbCategoryPlus />}
            component={<Link to="/admin/category" />}
          >
            {" "}
            Category
          </MenuItem>
          <MenuItem
            className={location.pathname === "/admin/blogs" ? "ps-active" : ""}
            icon={<TbHexagonLetterB />}
            component={<Link to="/admin/blogs" />}
          >
            Blogs
          </MenuItem>
        </Menu>
      </Sidebar>
      
    </div>
  );
}

export default AdminSideBar