
import { configureStore } from "@reduxjs/toolkit";
import categorySlice from "./features/category/categorySlice";
import blogSlice from "./features/Blog/blogSlice";

export default configureStore({
  reducer: {
    category: categorySlice,
    blog: blogSlice,
  },
});
